import gql from 'graphql-tag'

export const fragmentCommentList = gql`
	fragment CommentList on Comment {
		isPending
		id
		createdAt
		body
		author {
			id
			username
			fullname
			profileImage {
				id
				uri(type: THUMBNAIL_SMALL)
			}
		}
		viewerCanUpdate
		viewerCanDelete
	}
`