import React from 'react'
import {EntityLink} from 'common/Links'
import {User as UserIcon} from 'react-feather'
import {cn} from 'utils'

export default function Avatar({user, size='sm', className, noLink, ...rest})
{
	const Comp = noLink ? 'div' : EntityLink
	const props = noLink ? rest : {...rest, to: user} 

	return (
		<Comp
			{...props}
			className={cn('avatar', `avatar-${size}`, className)} 
		>
			{user.profileImage
				? (
		      <img 
		        className="avatar-img rounded-circle"
		        src={user.profileImage.uri} 
		        alt={`${user.username}'s avatar`}
		        title={user.username}
		      />
				)
				: (
					<div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
						<UserIcon size={17}/>
					</div>
				)
			}

		</Comp>
	)
}