import React from 'react'
import {Modal as ModalBase} from 'bootstrap'
import {ModalInstanceContext} from 'context'
import {useEffect, useRef, useState, useContext} from 'hooks'

export function Modal({children})
{
  const {isOpen, close, unmount} = useContext(ModalInstanceContext)
  const modalRef = useRef()
  const [modal, setModal] = useState()
  const modalStateRef = useRef()
  modalStateRef.current = modal

  useEffect(() => {
    if (modalRef.current) {
      setModal(new ModalBase(modalRef.current))
      modalRef.current.addEventListener('hidden.bs.modal', close)
    }

    return () => modalStateRef.current.dispose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let timer
    if (modal) {
      if (isOpen) {
        modal.show()
      } else {
        modal.hide()
        timer = setTimeout(unmount, 500)
      }
    }
    return () => timer && clearTimeout(timer)
  }, [modal, isOpen, unmount])

  return (
    <div ref={modalRef} className="modal fade" tabIndex="-1">
      <div className="modal-dialog modal-fullscreen-sm-down">
        <div className="modal-content">
          {children}
        </div>
      </div>
    </div>
  )
}