import React from 'react'
import {useFormContext} from 'react-hook-form'

export function Hidden({id,	name, ...props})
{
	const {register} = useFormContext()

	return (
		<input 
			{...register(name)} 
			type="hidden" 
			id={id || name} 
			{...props}
		/>
	)	
}