import {useOnlineStatus, useEffect, useRef} from 'hooks'
import {useQuery as useBaseQuery} from '@apollo/client'

/**

  const [fetchPolicy, setFetchPolicy] = useState<FetchPolicy>('cache-only')

  useEffect(() => {
    AsyncStorage.getItem(key)
      .then(lastFetch => {
        if (
          lastFetch === null ||
          moment().diff(moment(lastFetch)) > expiration.asMilliseconds()
        ) {
          AsyncStorage.setItem(key, moment().toISOString()).catch(() =>
            console.warn(`Failed to save time of last fetch for ${key}`)
          )
          setFetchPolicy('network-only')
        } else {
          setFetchPolicy('cache-first')
        }
      })
      .catch(() => setFetchPolicy('network-only'))
  }, [])

  return fetchPolicy

 */

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function useQuery(query, {refetchOnReconnect=false, ...options} = {})
{
	const isOnline  = useOnlineStatus()
	const wasOnline = usePrevious(isOnline)

	const {fetchPolicy='cache-first'} = options

	const result = useBaseQuery(query, {
		...options,
		fetchPolicy: isOnline ? fetchPolicy : 'cache-only'
	})

	const {refetch} = result
	useEffect(() => {
		if (refetchOnReconnect && isOnline && !wasOnline) {
			refetch()
		}
	}, [isOnline, wasOnline, refetch, refetchOnReconnect])

	return result
}