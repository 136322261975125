import React from 'react'
import {Modal} from 'common/modals'
import {Button} from 'common/buttons'
import Form, {Textarea} from 'form'

import {useMutation} from 'hooks'
import {mutationUpdatePost} from 'graph'
import {pick} from 'lodash';


export function UpdatePostModal(props)
{
	const {post, close} = props
	const [doUpdatePost, {loading}] = useMutation(mutationUpdatePost, {
		onCompleted(/*data*/) {
			close();
		},
		onError(/*error*/) {}
	});

	const onSubmit = (input) => doUpdatePost({
		variables: {
			input: {
				...input,
				id: post.id,
			}
		}
	});

	return (
		<Modal {...props}>
			<Form 
				onSubmit={onSubmit}
				defaultValues={pick(post, ['body'])}
			>
				<div className="modal-body">
					<div>
		      	<h2><small className="text-muted">Update</small><br/>Post</h2>
		      </div>
		      <Textarea
		      	name="body"
		      	placeholder="Content"
		      />
		    </div>
		    <div className="modal-footer">
		      <Button variant="outline-secondary" type="reset" onClick={close}>Cancel</Button>
		      <Button variant="outline-success" type="submit" disabled={loading}>Update</Button>
		    </div>
		  </Form>
		</Modal>
	)
}