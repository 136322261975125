import React from 'react'
import {FeedNext} from 'common/Feed'

import {useMe} from 'hooks'
import {queryPublicFeed, queryPublicFeedNew} from 'graph'

function PublicFeed()
{
	const me = useMe()
	const resolve = data => data?.publicFeed

	return (
		<>
			<div className="row text-center my-4">
				<div className="col">
					<h1 className="display-3">
						{me ? `Hello ${me.firstname}!` : 'Hello!'}<br/>
						<span className="display-4 text-muted fw-lighter">That’s happening on Thruhiker right now</span></h1>
				</div>
			</div>
			<div className="row justify-content-md-center">
				<div className="col-sm-12 col-md-10 col-lg-10 col-xl-8 col-xxl-8">
			    <FeedNext
			      withAdventureRef
			      query={queryPublicFeed}
			      queryMonitor={queryPublicFeedNew}
			      resolveConnection={resolve}
			    />
				</div>
			</div>
		</>
	)
}

/*
function PersonalFeed({data})
{

}
*/


function Home({data})
{
	return (
		<>
			<PublicFeed />
		</>
	)
}

export default Home
