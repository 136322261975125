import React from 'react';
import {Link as LinkIcon} from 'react-feather';
import {Link, NavLink} from 'react-router-dom';
import {qs} from 'utils';

export {Link, NavLink};

const DISABLED_TYPES = [];

export function makeEntityUrl(entity, options = {}) {
	const {query = {}, preserveQuery = true} = options;
	const parts = [
		entity.__typename.toLowerCase() + 's',
		entity.username || entity.id,
	];
	if (entity.slugTitle || entity.slug) {
		parts.push(entity.slugTitle || entity.slug);
	}

	let path = '/' + parts.join('/');

	const {search} = window.location;
	if (query || (preserveQuery && search)) {
		const params = preserveQuery ? qs.parse(search) : {};
		path = path + '?' + qs.stringify({...params, ...query});
	}

	return path;
}

export function EntityLink({to, size = 13, children, disabled, ...rest}) {
	if (!to || disabled || DISABLED_TYPES.includes(to.__typename)) {
		return children || null;
	}

	const title =
		to.title ||
		to.fullname ||
		(to.firstname && to.lastname && `${to.firstname} ${to.lastname}`);
	const url = makeEntityUrl(to);

	return (
		<Link title={title} {...rest} to={url}>
			{children || <LinkIcon size={size} />}
		</Link>
	);
}

export function EmailLink({to, children, subject, disabled, ...props}) {
	if (disabled) {
		return children;
	}

	return (
		<a
			href={`mailto:${to}${
				subject && '?subject=' + encodeURIComponent(subject)
			}`}
		>
			{children || to}
		</a>
	);
}
