import React from 'react'
import {useState} from 'hooks'
import {uuidv4} from 'utils'

export const ModalContext = React.createContext({})
export const ModalInstanceContext = React.createContext({})

function useModalState() {
	const [stack, setStack] = useState({})

	const open = (component, props={}) => {
		const id = uuidv4()
		setStack({
			...stack, 
			[id]: {
				component,
				props,
				isOpen: true
			}
		})

		return id
	}

	const close = id => {
		setStack({
			...stack,
			[id]: {
				...stack[id],
				isOpen: false
			}
		})
	}

	const update = (id, props={}) => {
		const nextStack = {}
		for (const [key, value] of Object.entries(stack)) {
		  if (key === id) {
		  	nextStack[key] = {
		  		...value,
		  		props
		  	}
		  }
		  else {
				nextStack[key] = value
		  }
		}

		setStack(nextStack)
	}

	const unmount = id => {
		const {[id]:_, ...nextStack} = stack
		setStack(nextStack) 
	}

	return {
		stack, 
		open,
		update,
		close,
		unmount
	}
}

export function ModalProvider({children}) {

	const state = useModalState()

	return (
		<ModalContext.Provider value={state}>
			{children}
		</ModalContext.Provider>
	)
}

export function ModalInstanceProvider({children, ...value})
{
	return (
		<ModalInstanceContext.Provider value={value}>
			{children}
		</ModalInstanceContext.Provider>
	)
}