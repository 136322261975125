import React from 'react'
import {PrivateIcon} from 'common/Icons'

function PrivateAlert({title, body})
{
	return (
			<div className="private-wrap d-flex flex-column justify-content-center">
				<div className="d-inline text-secondary">
					<PrivateIcon size={75} strokeWidth={.7}/>
				</div>
				<div class="text-secondary">
					<div className="private-title fs-1">{title}</div>
					<div className="private-body">{body}</div>
				</div>
		</div>
	)
}

export function PrivateUserAlert({user})
{
	const {firstname} = user
	return (
		<PrivateAlert
			title="This user account is private!"
			body={`Subscribe to ${firstname} to see his/her content.`}
		/>
	)
}

const ADVENTURE_PRIVACY_TO_BODY_MAP = {
  PRIVATE: 'You need to participate in this adventure to see the content.',
  SUBSCRIBERS: 'You need to subscribe at least one of the participators to see the content.'
}

export function PrivateAdventrueAlert({adventure})
{
	const {privacy} = adventure

	return (
		<PrivateAlert
			title="This this adventure is private!"
			body={ADVENTURE_PRIVACY_TO_BODY_MAP[privacy]}
		/>
	)
}