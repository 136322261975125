import React from 'react';
import {Slider} from 'common/slider/Slider';
import ShowMore from 'react-show-more';
import {FeedContext, PostContext, PostProvider} from 'context';
import {PostContextDropdown, CommentContextDropdown} from 'common/Dropdown';
import {PostPendingAlert, CommentPendingAlert} from 'common/PendingAlert';
import {EntityLink} from 'common/Links';
import Avatar from 'common/Avatar';
import {SubmitIcon} from 'common/Icons';
import TextWithLinebreaks from 'common/TextWithLinebreaks';
import Quote from 'common/Quote';

import {useEffect, useMutation, useContext, useState, useMe} from 'hooks';
import {mutationCommentPost, fragmentCommentList} from 'graph';
import {makeId, cn, relativeDate, relativeDuration} from 'utils';

export function Photos() {
    const {fotos} = useContext(PostContext);

    if (fotos.length === 0) {
        return null;
    }

    const slides = fotos.map((photo) => (
        <img src={photo.image.uri} alt="Caption is a todo" />
    ));

    return (
        <Slider slides={slides} />
    );
}

function MakeComment() {
    const me = useMe();
    const post = useContext(PostContext);

    const [body, setBody] = useState('');
    const [doComment, {loading}] = useMutation(mutationCommentPost, {
        update(cache, {data}) {
            const newComment = data.commentPost.comment;
            cache.modify({
                broadcast: false,
                id: cache.identify(post),
                fields: {
                    comments({pagination = {}, edges = {}}, {readField}) {
                        const newCommentRef = cache.writeFragment({
                            data: newComment,
                            fragment: fragmentCommentList,
                        });
                        return {
                            pagination: {
                                ...pagination,
                                total: pagination.total + 1,
                            },
                            edges: {
                                ...edges,
                                [readField('id', newComment)]: newCommentRef,
                            },
                        };
                    },
                },
            });
        },
    });

    const submitComment = () => {
        const trimmedBody = body.trim();

        // Dont submit if comment is empty
        if (trimmedBody === '') {
            return;
        }

        const clientId = makeId('Comment');
        const clientCreatedAt = new Date().toISOString();

        doComment({
            variables: {
                input: {
                    clientId,
                    clientCreatedAt,
                    targetId: post.id,
                    body: trimmedBody,
                },
            },
            optimisticResponse: {
                commentPost: {
                    __typename: 'CreateCommentPayload',
                    comment: {
                        __typename: 'Comment',
                        isPending: true,
                        id: clientId,
                        createdAt: clientCreatedAt,
                        body: trimmedBody,
                        author: me,
                        viewerCanDelete: true,
                        viewerCanUpdate: true,
                    },
                },
            },
        });

        setBody('');
    };

    const onClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        submitComment();
    };

    const onKeyDown = (e) => {
        // Default behavior only when enter is
        // pressed together with shift key
        if (e.key !== 'Enter' || e.shiftKey) {
            return;
        }

        // Prevent default on submit
        e.preventDefault();

        // Submit
        submitComment();
    };

    if (!me) {
        return null;
    }

    return (
        <>
            <hr />
            <div className="row">
                <div className="col-auto">
                    <Avatar user={me} noLink />
                </div>
                <div className="col ml-n2 mt-1">
                    <label className="visually-hidden">
                        Leave a comment...
                    </label>
                    <textarea
                        className="form-control form-control-flush"
                        rows="1"
                        placeholder="Leave a comment"
                        style={{
                            overflow: 'hidden',
                            overflowWrap: 'break-word',
                            height: 41,
                        }}
                        value={body}
                        disabled={loading}
                        onChange={(e) => setBody(e.target.value)}
                        onKeyDown={onKeyDown}
                    />
                </div>
                {body.trim() !== '' && (
                    <div className="col-auto d-flex align-items-center">
                        <a href="#submit-comment" onClick={onClick}>
                            <SubmitIcon size={22} />
                        </a>
                    </div>
                )}
            </div>
        </>
    );
}

export function Comments() {
    const {comments} = useContext(PostContext);

    return (
        <div className="card-body pt-0">
            {comments.pagination.total > 0 && <hr />}
            {comments.edges.map((comment) => (
                <Comment key={comment.id} comment={comment} />
            ))}
            <MakeComment />
        </div>
    );
}

function Comment({comment}) {
    const {isPending, body, author, createdAt} = comment;
    return (
        <div className="comment mb-3">
            <div className="row">
                <div className="col-auto">
                    <Avatar user={author} />
                </div>
                <div className="col ml-n2">
                    <div className="comment-body">
                        <div className="row">
                            <div className="col">
                                <h5 className="comment-title">
                                    {author.fullname}
                                </h5>
                            </div>
                            <div className="col-auto">
                                <Time
                                    createdAt={createdAt}
                                    className="comment-time"
                                />
                            </div>
                        </div>
                        <TextWithLinebreaks
                            text={body}
                            className="comment-text"
                        />
                    </div>
                    <CommentPendingAlert isPending={isPending} />
                </div>
                <div className="col-auto">
                    <CommentContextDropdown comment={comment} />
                </div>
            </div>
        </div>
    );
}

function UserRef({user}) {
    return (
        <EntityLink to={user} className="th-user-ref">
            {user.fullname}
        </EntityLink>
    );
}

function AdventureRef({adventure}) {
    return (
        <>
            {' '}
            on{' '}
            <EntityLink to={adventure} className="th-adventure-ref">
                {adventure.title}
            </EntityLink>
        </>
    );
}

function Time({createdAt, className}) {
    const [relativeTime, setRelativeTime] = useState(relativeDate(createdAt));
    useEffect(() => {
        const duration = relativeDuration(createdAt);
        // This effect only works when post is not older than 24 hours
        if (duration < 24 * 60 * 60) {
            let interval = 1000;
            if (duration > 60) {
                interval = 60 * 1000;
            }
            if (duration > 60 * 60) {
                interval = 60 * 60 * 1000;
            }
            const timer = setInterval(
                () => setRelativeTime(relativeDate(createdAt)),
                interval
            );

            // clear
            return () => clearInterval(timer);
        }
    }, [createdAt, relativeTime]);

    return (
        <time dateTime={createdAt} className={cn(className)}>
            <i className="fe fe-clock" />
            &nbsp;{relativeTime}
        </time>
    );
}

function PostHeader() {
    const {withAdventureRef} = useContext(FeedContext);
    const {type, author, adventure, location, createdAt, isPending} =
        useContext(PostContext);

    if (type === 'ADVENTURE_CREATED') {
        return (
            <div className="card-body text-center">
                <h2>
                    Adventure created
                    <br />
                    <span className="display-1">
                        <EntityLink to={adventure} className="th-adventure-ref">
                            {adventure.title}
                        </EntityLink>
                    </span>
                </h2>
                <Quote name={'createAdventure'} />
            </div>
        );
    }

    return (
        <>
            <div className="card-body">
                <div className="row align-items-center mb-0">
                    <div className="col-auto">
                        <Avatar user={author} />
                    </div>
                    <div className="col" style={{minWidth: 0}}>
                        <h4 className="mb-1 d-block text-truncate">
                            <UserRef user={author} />
                            {withAdventureRef && (
                                <AdventureRef adventure={adventure} />
                            )}
                        </h4>
                        <div className="row justify-content-start card-text small text-muted">
                            <div className="col-auto">
                                <Time
                                    createdAt={createdAt}
                                    isPending={isPending}
                                    className="post-time"
                                />
                            </div>
                            {location && (
                                <div
                                    className="col text-truncate ps-0"
                                    stlye={{minWidht: 0}}
                                >
                                    <span>
                                        <i className="fe fe-map-pin" />
                                        &nbsp;{location.displayName}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-auto">
                        <PostContextDropdown />
                    </div>
                </div>
            </div>
            <PostPendingAlert isPending={isPending} />
        </>
    );
}

function PostBody() {
    const {body} = useContext(PostContext);

    if (!body) {
        return null;
    }

    return (
        <div className="card-body">
            <ShowMore lines={10}>
                <TextWithLinebreaks text={body} />
            </ShowMore>
        </div>
    );
}

export function Post({post}) {
    return (
        <PostProvider post={post}>
            <article className="card mb-2">
                <PostHeader />
                <Photos />
                <PostBody />
                <Comments />
            </article>
        </PostProvider>
    );
}
