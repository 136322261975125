import {quotes, sample} from 'utils'
import {useState, useEffect} from 'hooks'

// Our hook
export default function useQuote(name) 
{
  if (!quotes[name]) {
		throw new Error(`There is no quote source named ${name}`)
  }

  const [quote, setQuote] = useState({
  	text: null,
  	source: null
  })

  useEffect(() => {
  	setQuote(sample(quotes[name]))
  }, [name])

  return quote
}

