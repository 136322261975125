import React from 'react'
import {useQuote} from 'hooks'

export default function Quote({name})
{
	const quote = useQuote(name)
	return (
		<figure>
		  <blockquote className="blockquote">
		    <p>{quote.text}</p>
		  </blockquote>
		  <figcaption className="blockquote-footer">
		    <cite title="Source">{quote.source}</cite>
		  </figcaption>
		</figure>
	)
}