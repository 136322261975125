import gql from 'graphql-tag'
import {fragmentPostDetail} from './post'

export const queryPersonalFeed = gql`
	query personalFeed {
		id
		body
		adventure {
			id
			title
		}
		fotos {
			id
			caption
			image {
				id
				url
			}
		}
	}
`

export const queryPublicFeed = gql`
	query publicFeed($offset: Int, $limit: Int) {
		publicFeed(offset: $offset, limit: $limit) {
			pagination {
				offset
				limit
				total
			}
			edges {
				...PostDetail
			}
		}
	}
	${fragmentPostDetail}
`

export const queryPublicFeedNew = gql`
	query publicFeed {
		publicFeed(offset: 0, limit: 0) {
			pagination {
				total
			}
		}
	}
`