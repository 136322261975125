import React from 'react'
import {Dropdown as DropdownBase} from 'bootstrap'
import {PostContext} from 'context'
import {ContextIcon} from 'common/Icons'
import {SecureButton} from 'common/buttons'
import {EntityLink} from 'common/Links'
import {UpdatePostModal, ReportPostModal} from 'modals'

import {useEffect, useRef, useModal, useContext, useMutation} from 'hooks'
import {mutationDeletePost} from 'graph'
import {cn} from 'utils'

export const POST_PRIVACY_MAP = {
  PRIVATE: {
    name: 'Private',
    description: 'Only you can see this post'
  }, 
  PARTICIPATORS: {
    name: 'Participators',
    description: 'Only direct participators can read this post'
  },
  SUBSCRIBERS: {
    name: 'My subscribers',
    description: 'Only your subscribers can read this post'
  },
  SUBSCRIPBERS_OF_PARTICIPATIOS: {
    name: 'All subscribers',
    description: 'All subscribers of participators can read this post'
  },
  PUBLIC: {
    name: 'Public',
    description: 'Everyone can read this post'
  }
}

export const ADVENTURE_PRIVACY_MAP = {
  PRIVATE: {
    name: 'Private',
    description: 'Visible to author and participators',
    postPrivacy: 'PARTICIPATORS'
  },
  SUBSCRIBERS: {
    name: 'Subscribers',
    description: 'Visible to subscribers of participators',
    postPrivacy: 'SUBSCRIPBERS_OF_PARTICIPATIOS'
  },
  PUBLIC: {
    name: 'Public',
    description: 'Visible to everyone',
    postPrivacy: 'PUBLIC'
  },
}

export function useDropdownRef() {
  const dropdownRef = useRef()
  useEffect(() => {
      const bsDropdown = DropdownBase.getInstance(dropdownRef.current) || new DropdownBase(dropdownRef.current)

      return () => {
        bsDropdown.dispose()
      }
  }, [])

  return dropdownRef
}

export function PostPrivacy({value, onChange})
{
  const dropdownRef = useDropdownRef()
  return (
    <div className="dropdown">
      <button 
        ref={dropdownRef} 
        className="btn btn-outline-primary text-start dropdown-toggle"
        type="button" id="post-privacy"
        aria-expanded="false"
        data-bs-toggle="dropdown"
        onClick={e => e.preventDefault()}
      >
        <span style={{whiteSpace: 'normal'}}>
          {POST_PRIVACY_MAP[value].description}
        </span>
      </button>
      <div className="dropdown-menu" aria-labelledby="post-privacy">
        <div className="list-group list-group-flush">
          <div className="list-group-item p-3">
            <strong className="text-primary">Privacy is important</strong><br/>
            <small className="text-muted">Choose who will be able to read this post</small>
          </div>
          {Object.entries(POST_PRIVACY_MAP).map(([key, privacy]) => (
              <button onClick={() => onChange(key)} key={key} className={cn('list-group-item', 'list-group-item-action', 'p-3', {active: key === value})}>
                {privacy.name}<br/>
                <span className="small">{privacy.description}</span>
              </button>
          ))}
        </div>
      </div>
    </div>
  )
}

export function AdventurePrivacy({value, onChange})
{
  const dropdownRef = useDropdownRef()
  return (
    <div className="dropdown">
      <button 
        ref={dropdownRef} 
        className="btn btn-outline-primary text-start dropdown-toggle"
        type="button" id="adventure-privacy"
        aria-expanded="false"
        data-bs-toggle="dropdown"
        onClick={e => e.preventDefault()}
      >
        <span style={{whiteSpace: 'normal'}}>
          {ADVENTURE_PRIVACY_MAP[value].description}
        </span>
      </button>
      <div className="dropdown-menu" aria-labelledby="post-privacy">
        <div className="list-group list-group-flush">
          <div className="list-group-item p-3">
            <strong className="text-primary">Privacy is important</strong><br/>
            <small className="text-muted">Choose who will be able to access this adventure</small>
          </div>
          {Object.entries(ADVENTURE_PRIVACY_MAP).map(([key, privacy]) => (
              <button onClick={() => onChange(key)} key={key} className={cn('list-group-item', 'list-group-item-action', 'p-3', {active: key === value})}>
                {privacy.name}<br/>
                <span className="small">{privacy.description}</span>
              </button>
          ))}
        </div>
      </div>
    </div>
  )
}

export function CommentContextDropdown({comment})
{
  const dropdownRef = useDropdownRef()

  return (
    <div className="dropdown">
      <button ref={dropdownRef} className="btn btn-link text-muted" data-bs-toggle="dropdown">
        <ContextIcon size={17} />
      </button>
      <ul className="dropdown-menu dropdown-menu-end">
        {comment.viewerCanUpdate && (
          <li>
            <a 
              className="dropdown-item" 
              href="#edit-comment"
              onClick={() => {}}
            >
              Update
            </a>
          </li>
        )}
        <li>
          <a 
            className="dropdown-item"
            href="#report-comment"
            onClick={() => {}}
          >
            Report
          </a>
        </li>
        {comment.viewerCanDelete && (
          <li>
            <SecureButton 
              className="dropdown-item text-danger" 
              onClick={() => {}}
            >
              Delete
            </SecureButton>
          </li>
        )}
      </ul>
    </div>
  )
}

export function PostContextDropdown()
{
  const post = useContext(PostContext)

  const openUpdate = useModal(UpdatePostModal, {post})
  const openReport = useModal(ReportPostModal, {post})
  const [doDelete, {loading: deleting}] = useMutation(mutationDeletePost, {
    variables: {
      input: {
        id: post.id
      }
    },
    update(cache, {data}) {
      if (data.deletePost.success) {
        const normalizedId = cache.identify(data.deletePost.post)
        cache.evict({id: normalizedId})
        cache.gc()
      }
    }
  })

  const onDeleteClick = e => {
    e.preventDefault()
    doDelete({
      optimisticResponse: {
        deletePost: {
          success: true,
          post: {
            id: post.id,
            __typename: 'Post'
          },
          __typename: 'DeletePostPayload'
        }
      }
    })
  }

  const dropdownRef = useDropdownRef()

  return (
    <div className="dropdown">
      <button ref={dropdownRef} className="btn btn-link text-muted" data-bs-toggle="dropdown">
        <ContextIcon size={21} />
      </button>
      <ul className="dropdown-menu dropdown-menu-end">
        <li>
          <EntityLink 
            to={post.adventure}
            className="dropdown-item" 
          >
            Adventure
          </EntityLink>
        </li>
        <li>
          <EntityLink 
            to={post.author}
            className="dropdown-item" 
          >
            Author
          </EntityLink>
        </li>
        {post.viewerCanUpdate && (
          <li>
            <a 
              className="dropdown-item" 
              href="#edit-post"
              onClick={e => e.preventDefault() || openUpdate()}
            >
              Update
            </a>
          </li>
        )}
        <li>
          <a 
            className="dropdown-item"
            href="#report-post"
            onClick={e => e.preventDefault() || openReport()}
          >
            Report
          </a>
        </li>
        {post.viewerCanDelete && (
          <li>
            <SecureButton 
              className="dropdown-item text-danger" 
              onClick={onDeleteClick}
              disabled={deleting}
            >
              Delete
            </SecureButton>
          </li>
        )}
      </ul>
    </div>
  )
}