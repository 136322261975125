import React from 'react'
import Button from './Button'
import {useState, useEffect, useRef} from 'hooks'

export default function SecureButton({onClick, children, ...props})
{
	const buttonRef = useRef()
	const [enabled, setEnabled] = useState(false)

	useEffect(() => {
		const enable = e => {
			e.stopPropagation()
			e.preventDefault();
			setEnabled(true)
		}
		const {current} = buttonRef
		if (current) {
			if (!enabled) {
				current.addEventListener('click', enable)
			}
			else {
				current.removeEventListener('click', enable)
			}
		}
		return () => current && current.removeEventListener('click', enable)
	}, [enabled])

	return (
		<Button {...props} ref={buttonRef} onClick={onClick}>
			{children}
			{enabled && ' Sure?'}
		</Button>
	)
}