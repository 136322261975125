/* eslint-disable no-unused-vars */

import React from 'react';
import {Redirect} from 'react-router-dom';
import Form, {Input, Password} from 'form';
import {Button} from 'common/buttons';
import {Link} from 'common/Links';

import {useMe, useMutation, useParams} from 'hooks';
import {mutationLogin} from 'graph';

function StepOne() {
    const onSubmit = () => {};
    const loading = false;
    // const [sendNotification, {client, loading}] = useMutation(mutationSendResetPasswordNotification)
    return (
        <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
                <Form onSubmit={onSubmit} autocomplete="off">
                    <Input
                        name="username"
                        label="Username or Email Address"
                        placeholder="name@address.com"
                        rules={{
                            required:
                                'Please enter your username or email address',
                        }}
                    />
                    <div className="form-text text-muted mb-3">
                        Please enter your username or email address an we'll
                        send you a link to reset your password.
                    </div>

                    <div className="d-grid my-3">
                        <Button
                            type="submit"
                            size="lg"
                            disabled={loading}
                        >
                            Email me a reset link
                        </Button>
                    </div>
                    <div className="text-center">
                        <small className="text-muted text-center">
                            It came back to you?{' '}
                            <Link to="sign-in">Sign in</Link>.
                        </small>
                    </div>
                </Form>
            </div>
        </div>
    );
}

function StepTwo({token}) {
    const onSubmit = () => {};
    const loading = false;
    // const [sendNotification, {client, loading}] = useMutation(mutationSendResetPasswordNotification)
    return (
        <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
                <Form onSubmit={onSubmit} autocomplete="off">
                    <Password
                        name="password"
                        label="New Password"
                        placeholder="Your new password"
                        rules={{
                            required:
                                'Please enter your new password',
                        }}
                    />
                    <div className="form-text text-muted mb-3">
                        Please enter your new password.
                    </div>

                    <div className="d-grid my-3">
                        <Button
                            type="submit"
                            size="lg"
                            disabled={loading}
                        >
                            Change it
                        </Button>
                    </div>
                    <div className="text-center">
                        <small className="text-muted text-center">
                            It came back to you?{' '}
                            <Link to="sign-in">Sign in</Link>.
                        </small>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default function ResetPassword() {
    const me = useMe();
    //
    //const [resetPassword] = useMutation(mutationResetPassword)
    const {token} = useParams();

    if (me) {
        return <Redirect to="/me" />;
    }

    /*
  const onSubmit = input => login({
    variables: {
      input
    }
  }).then(client.resetStore)
  */

    const onSubmit = () => {};
    const loading = false;

    return (
        <div className="container">
            <div className="row text-center my-4">
                <div className="col">
                    <h1 className="display-3">
                        Reset Password
                        <br />
                        <span className="display-4 text-muted fw-lighter">
                            Don’t worry, happens to the best of us
                        </span>
                    </h1>
                </div>
            </div>
            {!token && <StepOne />}
            {token && <StepTwo token={token} />}
        </div>
    );
}
