import React from 'react'
import {useFormContext} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'
import Help from 'form/Help'
import {Link} from 'common/Links'
import {get, cn} from 'utils'

export default function Password({
	id, 
	name, 
	label, 
	help,
	hint,
	rules, 
	className,
	outerClassName,
	placeholder='Password', 
	...props}
) {
	const {register, defaultPlaceholders, formState: {errors}} = useFormContext()

	return (
		<div className={cn('form-floating', outerClassName)}>
			<input 
				{...register(name, rules)} 
				type="password"
				className={cn('form-control', className, {'is-invalid': get(errors, name)})}
				id={id || name} 
				placeholder={placeholder || get(defaultPlaceholders, name)}
				{...props}
			/>
			{label && (
				<label htmlFor={name}>
					{label}
					<Help help={help}/>
				</label>
			)}
      <div className="row">
				<ErrorMessage 
					name={name} 
					errors={errors}
					render={({ message }) => (
						<div className="col">
							<span className="invalid-feedback form-text small">{message}</span>
						</div>
					)}
				/>
        {hint && (<div className="col">
          <Link to="reset-password" className="form-text text-end small text-muted" tabIndex={-1}>
            Forgot password?
          </Link>
        </div>)}
      </div>
		</div>
	)	
}
