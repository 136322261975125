import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {animated} from 'react-spring';
import {useSlider, useOnScreen} from 'hooks';
import {Dots} from './Dots';
import {Slide} from './Slide';
import {SlideIndicator} from './SlideIndicator';
import {
    Overlay as StyledOverlay,
    SlideOverlay as StyledSlideOverlay,
    Slider as StyledSlider,
    Container as StyledContainer,
} from './Slider.css';

const AnimatedOverlay = animated(StyledOverlay);
const AnimatedSlider = animated(StyledSlider);

const defaultProps = {
    initialSlide: 0,
    maxScale: 4,
    minScale: 1,
    slideIndicatorTimeout: 5000,
    activeDotColor: '#4e99e9',
    dotColor: '#dadbdc',
};

export function Slider({
    initialSlide,
    slides,
    slideOverlay,
    slideIndicatorTimeout,
    activeDotColor,
    dotColor,
    auto,
}) {
    const containerRef = useRef();
    const {zooming, scale, currentSlide, bind, x, onScale, updateSlide} =
        useSlider({
            containerRef,
            initialSlide,
            slides,
        });

    // eslint-disable-next-line no-unused-vars
    const onScreen = useOnScreen(containerRef);

    function autoUpdateSlide() {
        updateSlide(
            currentSlide === slides.length - 1 ? 0 : currentSlide + 1
        );
    }

    useEffect(() => {
        let timer;
        if (auto && slides.length > 1) {
            window.setInterval(autoUpdateSlide, auto);
        } else {
            window.clearInterval(timer);
        }

        return () => timer && window.clearInterval(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auto]);

    return (
        <StyledContainer ref={containerRef}>
            {zooming && (
                <AnimatedOverlay
                    style={{
                        backgroundColor: scale
                            .interpolate({
                                range: [1, 2, 10],
                                output: [0, 0.7, 0.7],
                            })
                            .interpolate(
                                (opacity) => `rgba(0, 0, 0, ${opacity})`
                            ),
                    }}
                />
            )}

            <StyledSlideOverlay inFront={!zooming}>
                {slideOverlay}
                <SlideIndicator
                    slideIndicatorTimeout={slideIndicatorTimeout}
                    currentSlide={currentSlide}
                    totalSlides={slides.length}
                />
            </StyledSlideOverlay>

            <AnimatedSlider
                isZooming={zooming}
                count={slides.length}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...bind()}
                style={{
                    transform: x.interpolate(
                        (slideX) => `translateX(${slideX}px`
                    ),
                }}
            >
                {slides.map((slide, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Slide onScale={onScale} key={idx}>
                        {slide}
                    </Slide>
                ))}
            </AnimatedSlider>

            {slides.length > 1 && (
                <Dots
                    totalSlides={slides.length}
                    currentSlide={currentSlide}
                    centerDots={slides.length < 6 ? slides.length : undefined}
                    dotColor={dotColor}
                    activeDotColor={activeDotColor}
                    updateSlide={updateSlide}
                />
            )}
        </StyledContainer>
    );
}

Slider.propTypes = {
    /** Index of the slide to be rendered by default */
    initialSlide: PropTypes.number,
    /** List of slides to render */
    slides: PropTypes.arrayOf(PropTypes.node).isRequired,
    /** Maximum zoom level */
    maxScale: PropTypes.number,
    /** Minimum zoom level */
    minScale: PropTypes.number,
    /** Content to overlay on the slider */
    slideOverlay: PropTypes.node,
    /** Time in ms until the slide indicator fades out. Set to `null` to disable this behavior. */
    slideIndicatorTimeout: PropTypes.number,
    /** Pagination dot color for the active slide */
    activeDotColor: PropTypes.string,
    /** Pagination dot color for all other slides */
    dotColor: PropTypes.string,
};

Slider.defaultProps = {
    initialSlide: defaultProps.initialSlide,
    maxScale: defaultProps.maxScale,
    minScale: defaultProps.minScale,
    slideOverlay: null,
    slideIndicatorTimeout: defaultProps.slideIndicatorTimeout,
    activeDotColor: defaultProps.activeDotColor,
    dotColor: defaultProps.dotColor,
};
