import gql from 'graphql-tag'
import {fragmentCommentList} from './comment'

export const fragmentPostDetail = gql`
	fragment PostDetail on Post {
		isPending
		id
		createdAt
		updatedAt
		type
		body
		day

		author {
			id
			username
			fullname
			profileImage {
				id
				uri(type: THUMBNAIL_SMALL)
			}
		}

		adventure {
			id
			title
		}

		location {
			hash
			displayName
		}

		comments {
			pagination {
				offset
				limit
				total
			}
			edges {
				...CommentList
			}
		}

		fotos {
			id
			caption
			image {
				id
				uri(type: SQUARE)
			}
		}

		viewerCanRead
		viewerCanUpdate
		viewerCanDelete
		viewerHasLiked
		viewerHasCommented
	}
	${fragmentCommentList}
`

export const mutationCreatePost = gql`
	mutation createPost($input: CreatePostInput!) {
		createPost(input: $input) {
			post {
				...PostDetail
			}
		}
	}
	${fragmentPostDetail}
`

export const mutationUpdatePost = gql`
	mutation updatePost($input: UpdatePostInput!) {
		updatePost(input: $input) {
			post {
				...PostDetail
			}
		}
	}
	${fragmentPostDetail}
`

export const mutationCommentPost = gql`
	mutation commentPost($input: CreateCommentInput!) {
		commentPost(input: $input) {
			comment {
				...CommentList
			}
		}
	}
	${fragmentCommentList}
`

export const mutationDeletePost = gql`
	mutation deletePost($input: DeletePostInput!) {
		deletePost(input: $input) {
			success
			post {
				id
			}
		}
	}
`