import React from 'react';
import {Post} from 'common/Post';
import {FeedProvider} from 'context/FeedContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Button} from 'common/buttons';
import {LoadingEllipsis} from 'common/Loading';

import {useQuery, useState, useEffect, useScrollDirection, useOnlineStatus} from 'hooks';
import {cn} from 'utils';

function NewPostsButton({refetch, loading}) {
    const {isScrollingUp, isScrollingDown} = useScrollDirection();
    const [show, setShow] = useState(true);
    const [display, setDisplay] = useState(true);

    // Toggle fade effect
    useEffect(() => {
        if (isScrollingUp === true) {
            setDisplay(true);
            setShow(true);
        }

        if (isScrollingDown === true) {
            setShow(false);
        }
    }, [isScrollingUp, isScrollingDown]);

    // Remove button from ui if not needed
    useEffect(() => {
        let timer;
        const hide = () => setDisplay(false);

        if (!show) {
            timer = window.setTimeout(hide, 500);
        }

        return () => timer && window.clearTimeout(timer);
    }, [show]);

    if (!display) {
        return null;
    }

    return (
        <Button
            disabled={loading}
            variant="success"
            large
            onClick={() => {
                refetch();
                window.scrollTo(0, 0);
            }}
            className={cn('position-absolute', 'fade', {show})}
        >
            New posts available
        </Button>
    );
}

export function FeedNext(props) {
    const {
        query,
        queryMonitor,
        resolveConnection,
        variables,
        withAdventureRef,
    } = props;

    const online = useOnlineStatus();
    const [newPostsAvailable, setNewPostsAvailable] = useState(false);

    const {data, loading, refetch, fetchMore} = useQuery(query, {
        fetchPolicy: online ? 'cache-and-network' : 'cache-only',
        nextFetchPolicy: 'cache-only',
        notifyOnNetworkStatusChange: true,
        variables: {
            ...variables,
            offset: 0,
            limit: 10,
        },
        onError(error) {
            console.log(error);
        },
        onCompleted() {
            setNewPostsAvailable(false);
        },
    });

    const {refetch: monitor} = useQuery(queryMonitor, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables,
        onError(error) {
            console.log(error);
        },
        onCompleted(newData) {
            setNewPostsAvailable(
                pagination.total < resolveConnection(newData).pagination.total
            );
        },
        skip: !data || !online,
    });

    // Poll for changes
    useEffect(() => {
        const timer = setInterval(() => {
            if (process.env.NODE_ENV === 'development') {
                console.log('Time is up. Looking for new posts ...');
            }

            monitor();
        }, 15000);

        return () => clearInterval(timer);
    }, [monitor]);

    //
    useEffect(() => {
        const onVisibilityChange = () => {
            if (document.hidden) {
                return;
            }

            if (process.env.NODE_ENV === 'development') {
                console.log('Visibility changed. Looking for new posts ...');
            }

            monitor();
        };
        document.addEventListener('visibilitychange', onVisibilityChange);
        return () =>
            document.removeEventListener(
                'visibilitychange',
                onVisibilityChange
            );
    }, [monitor]);

    const {edges, pagination} = resolveConnection(data) || {
        edges: [],
        pagination: {
            offset: 0,
            limit: 10,
            total: 0,
        },
    };

    const feedFetchMore = () => {
        fetchMore({
            variables: {
                offset: pagination.offset,
            },
        });
    };

    // Nothing to display
    // TODO: Sceleton here?
    if (!data) {
        return null;
    }

    return (
        <FeedProvider withAdventureRef={withAdventureRef}>
            {process.env.NODE_ENV === 'development' && (
                <div className="card">
                    <div className="card-body">
                        <Button
                            variant="outline-primary"
                            onClick={() => feedFetchMore()}
                        >
                            Fetch more
                        </Button>{' '}
                        <Button
                            variant="outline-primary"
                            onClick={() => refetch()}
                        >
                            Refetch
                        </Button>
                    </div>
                </div>
            )}
            {newPostsAvailable && (
                <div className="d-flex justify-content-center sticky-top py-3">
                    <NewPostsButton loading={loading} refetch={refetch} />
                </div>
            )}
            <LoadingEllipsis loading={loading} />
            <InfiniteScroll
                dataLength={edges.length}
                next={feedFetchMore}
                hasMore={pagination.total > edges.length}
                loader={<LoadingEllipsis loading={true} />}
                style={{
                    overflowX: 'hidden',
                }}
            >
                {edges.map((post) => (
                    <Post
                        key={`post-${post.id}`}
                        post={post}
                        withAdventureRef={withAdventureRef}
                    />
                ))}
            </InfiniteScroll>
        </FeedProvider>
    );
}

export function Feed({posts, withAdventureRef, hasMore, fetchMore, refetch}) {
    const pullDownProps = refetch
        ? {
              refreshFunction: refetch,
              pullDownToRefresh: true,
              pullDownToRefreshThreshold: 50,
              pullDownToRefreshContent: (
                  <h3 style={{textAlign: 'center'}}>
                      &#8595; Pull down to refresh
                  </h3>
              ),
              releaseToRefreshContent: (
                  <h3 style={{textAlign: 'center'}}>
                      &#8593; Release to refresh
                  </h3>
              ),
          }
        : {};
    return (
        <FeedProvider withAdventureRef={withAdventureRef}>
            <InfiniteScroll
                dataLength={posts.length}
                next={fetchMore}
                hasMore={hasMore}
                loader={<h4>Loading ...</h4>}
                {...pullDownProps}
            >
                {posts.map((post) => (
                    <Post
                        key={`post-${post.id}`}
                        post={post}
                        withAdventureRef={withAdventureRef}
                    />
                ))}
            </InfiniteScroll>
        </FeedProvider>
    );
}
