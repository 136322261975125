import React from 'react'
import {Link, NavLink} from 'react-router-dom'
import {
    Home as HomeIcon,
    User as UserIcon,
    Compass as ExploreIcon
} from 'react-feather'
import {Thruhiker} from 'common/Icons'

function NavItem(props)
{
    return (
        <li className="nav-item px-4">
            <NavLink className="nav-link" {...props}/>
        </li>
    )
}

export function Navigation()
{
    return (
        <nav className="navbar navbar-expand navbar-light bg-light">
            <div className="container d-flex justify-content-between">
                <Link className="navbar-brand fw-bold" to="/">
                    <Thruhiker strokeWidth={2}/><span className="ps-1">Thruhiker</span>
                </Link>
                <ul className="navbar-nav mb-2 mb-lg-0">
                    <NavItem to="/" exact>
                        <HomeIcon/>
                    </NavItem>
                    <NavItem to="/explore">
                        <ExploreIcon/>
                    </NavItem>
                    <NavItem to="/me">
                        <UserIcon/>
                    </NavItem>
                </ul>
            </div>
        </nav>
    )
}

export default Navigation