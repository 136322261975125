import React from 'react'
import {Redirect} from 'react-router-dom'
import {SubscribeUserButton} from 'common/buttons'
import {FeedNext} from 'common/Feed'
import {Button} from 'common/buttons'
import {LoadingPage} from 'common/Loading'
import {Link, EntityLink} from 'common/Links'
import {UserItemWithSubscription} from 'common/Items'
import Avatar from 'common/Avatar'
import {PrivateUserAlert} from 'common/Private'
import {UpdateUserModal, CreateAdventureModal} from 'modals'

import {
  useParams,
  useQuery,
  useMutation,
  useMe,
  useModal
} from 'hooks'
import {
  queryUserPage,
  queryUserFeed, 
  queryUserFeedNew,
  queryUserParticipations, 
  queryUserSubscriptionsSend, 
  queryUserSubscriptionsReceived, 
  mutationLogout
} from 'graph'
import {day, cn, relativeDate} from 'utils'

export function UserSubscriptionsSend({user})
{
  const {data, loading} = useQuery(queryUserSubscriptionsSend, {
    variables: {id: user.id}
  })

  if (loading) {
    return <LoadingPage/>
  }

  return (
    <div className="card">
      <div className="card-body">
        <ul className="list-group list-group-flush">
          {data.user.subscriptionsSend.pagination.total === 0 && (
            <li className="list-group-item">
              <div className="row align-items-center">
                <div className="col">
                  <h2>No subscriptions yet</h2>
                  <p className="mb-0">{user.fullname} has no subscriptions yet</p>
                </div>
              </div>
            </li> 
          )}
          {data.user.subscriptionsSend.edges.map(subscription => (
            <UserItemWithSubscription
              key={subscription.id}
              user={subscription.receiver}
            />
          ))}
        </ul>
      </div>
    </div>
  )
}

export function UserSubscriptionsReceived({user})
{
  const {data, loading} = useQuery(queryUserSubscriptionsReceived, {
    variables: {id: user.id}
  })

  if (loading) {
    return <LoadingPage/>
  }

  return (
    <div className="card">
      <div className="card-body">
        <ul className="list-group list-group-flush">
          {data.user.subscriptionsReceived.pagination.total === 0 && (
            <li className="list-group-item">
              <div className="row align-items-center">
                <div className="col">
                  <h2>No subscribers yet</h2>
                  <div>{user.fullname} has no subscribers yet</div>
                </div>
              </div>
            </li> 
          )}
          {data.user.subscriptionsReceived.edges.map(subscription => (
            <UserItemWithSubscription
              key={subscription.id}
              user={subscription.sender}
            />
          ))}
        </ul>
      </div>
    </div>
  )
}

export function UserParticipations({user})
{
  const {data, loading} = useQuery(queryUserParticipations, {
    variables: {id: user.id}
  })

  if (loading) {
    return <LoadingPage/>
  }

  return (
    <div className="card">
      <div className="card-body">
        <ul className="list-group list-group-flush">
          {data.user.participations.pagination.total === 0 && (
            <li className="list-group-item">
              <div className="row align-items-center">
                <div className="col">
                  <h2>No adventures yet</h2>
                  <div>{user.fullname} has no participated in any adventure yet</div>
                </div>
              </div>
            </li> 
          )}
          {data.user.participations.edges.map(participation => (
            <li
              className="list-group-item"
              key={participation.id}
            >
              <div className="row align-items-center">
                <div className="col" style={{minWidth: 0}}>
                  <h4 className="mb-1">
                    <EntityLink to={participation.adventure}>
                      {participation.adventure.title}
                    </EntityLink>
                  </h4>
                  <div className="text-muted text-truncate">{participation.adventure.description}</div>
                </div>
                <div className="col-auto">
                  <time className="small text-muted" dateTime="1988-10-24">
                    {relativeDate(participation.createdAt)}
                  </time>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export function UserMeta({user})
{
  return (
    <div className="card">
      <div className="card-body">
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <div className="row align-items-center">
              <div className="col">
                <h5 className="mb-0">
                  Joined
                </h5>
              </div>
              <div className="col-auto">
                <time className="small text-muted" dateTime="1988-10-24">
                  {day(user.createdAt).format('YYYY/MM/DD')}
                </time>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export function MeMeta({user})
{
  const me = useMe()
  const openCreateAdventure = useModal(CreateAdventureModal)
  const openUpdateUser = useModal(UpdateUserModal, {user})
  const [doLogout, {client, loading}] = useMutation(mutationLogout)

  if (user.id !== me?.id) {
    return null
  }

  return (
    <div className="card">
      <div className="card-body">
        <ul className="list-group list-group-flush">
          <li className="list-group-item py-1">
            <div className="row align-items-center">
              <Button
                variant="outline-primary"
                onClick={() => openCreateAdventure()}
              >
                Create new adventure
              </Button>
            </div>
          </li>
          <li className="list-group-item py-1">
            <div className="row align-items-center">
              <Button
                variant="outline-primary"
                disabled={loading}
                onClick={() => openUpdateUser()}
              >
                Edit profile
              </Button>
            </div>
          </li>
          <li className="list-group-item py-1">
            <div className="row align-items-center">
              <Button
                variant="outline-secondary"
                disabled={loading}
                onClick={() => doLogout().then(() => client.resetStore())}
              >
                Logout
              </Button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}



export function Me()
{
  const me = useMe()

  if (!me) {
    return <Redirect to="/sign-in?reason=account_required" />
  }

  return (
    <User username={me.username} />
  )
}

function UserFeed({user})
{
  const {username} = user
  const resolve = data => data?.user.posts

  return (
    <FeedNext
      withAdventureRef
      query={queryUserFeed}
      queryMonitor={queryUserFeedNew}
      resolveConnection={resolve}
      variables={{
        username
      }}
    />
  )
}

export default function User({username})
{
  const params = useParams()
  const {data, loading} = useQuery(queryUserPage, {
    variables: {
      username: username || params.username
    }
  })

  if (!data && loading) {
    return  <LoadingPage />
  }

  const user = data?.user
  const tab = params.tab || 'feed'

  if (!user) {
    return null
  }

  const {viewerCanRead} = user

  return (
    <>
      <div className="header">
        <div className="container-fluid">
          <div className="header-body mt-1 mt-md-2">
            <div className="row align-items-center">
              <div className="col-auto">
                <Avatar user={user} size="xxl" className="header-avatar-top" />
              </div>
              <div className="col mb-3 ml-n3 ml-md-n2">
                <h1 className="header-title">
                  {user.fullname}
                </h1>
                <div><span className="text-muted">@{user.username}</span> {user.biography}</div>
              </div>
              <div className="col-12 col-md-auto mt-2 mt-md-0 mb-md-3">
                <SubscribeUserButton user={user} />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col">
                <ul className="nav nav-tabs nav-overflow header-tabs">
                  <li className="nav-item" style={{marginLeft: 'auto'}}>
                    <Link to={`/users/${user.username}/feed`} className={cn('nav-link', {'active': tab === 'feed'})}>
                      Feed
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={`/users/${user.username}/adventures`} className={cn('nav-link', {'active': tab === 'adventures'})}>
                      Adventures
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={`/users/${user.username}/subscribers`} className={cn('nav-link', {'active': tab === 'subscribers'})}>
                      Subscribers
                    </Link>
                  </li>
                  <li className="nav-item" style={{marginRight: 'auto'}}>
                    <Link to={`/users/${user.username}/subscriptions`} className={cn('nav-link', {'active': tab === 'subscriptions'})}>
                      Subscriptions
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {viewerCanRead && (
        <div className="content">
          <div className="row">
            <div className="col-md-4 order-first order-md-last">
              <MeMeta
                user={user}
              />
              <UserMeta
                user={user}
              />
            </div>
            <div className="col-md-8 order-last order-md-first">
              {tab === 'feed' && (
                <UserFeed 
                  user={user}
                />
              )}
              {tab === 'adventures' && (
                <UserParticipations 
                  user={user}
                />
              )}
              {tab === 'subscribers' && (
                <UserSubscriptionsReceived 
                  user={user}
                />
              )}
              {tab === 'subscriptions' && (
                <UserSubscriptionsSend
                  user={user}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {!viewerCanRead && (
        <PrivateUserAlert user={user} />
      )}
    </>
  )
}