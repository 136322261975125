import React from 'react'
import {LoadingEllipsis} from 'common/Loading'

import {useQuery} from 'hooks'
import {queryMe} from 'graph'

const SessionContext = React.createContext({})

export const SessionProvider = ({clearCache, children}) => {
	const {loading, data, refetch} = useQuery(queryMe, {
		fetchPolicy: 'cache-and-network'
	});

	console.log('Updating session provider');

	if (!data && loading) {
		return <LoadingEllipsis />
	}

	const session = {...data, refetch, clearCache}
	return (
		<SessionContext.Provider value={session}>
			{children}
		</SessionContext.Provider>
	)
}
export const SessionConsumer = SessionContext.Consumer
export default SessionContext