import React from 'react';
import {Modal} from 'common/modals';
import {UserItemWithParticipation} from 'common/Items';

import {useMe, useQuery} from 'hooks';
import {queryUserSubscriptionsSend, queryAdventureParticipations} from 'graph';
import {differenceBy} from 'lodash';

/*
import {useMutation} from 'hooks'
import {mutationCreateAdventure} from 'graph'
*/

function Body({participants, candidates, adventure}) {
	const users = differenceBy(
		candidates.user.subscriptionsSend.edges.map((edge) => edge.receiver),
		participants.adventure.participations.edges.map(
			(edge) => edge.receiver
		),
		'id'
	);
	return (
		<div className="list-group list-group-flush my-n3">
			{users.map((user) => (
				<UserItemWithParticipation key={user.id} user={user} adventure={adventure} />
			))}
		</div>
	);
}

export function AddParticipatorModal({adventure, close}) {
	const me = useMe();
	const participants = useQuery(queryAdventureParticipations, {
		variables: {
			id: adventure.id,
		},
	});

	const candidates = useQuery(queryUserSubscriptionsSend, {
		variables: {
			id: me.id,
		},
	});

	const error = participants.error && candidates.error;
	const ready = !!(participants.data && candidates.data);

	return (
		<Modal>
			<div className="modal-body">
				{error && <h2>Something went wrong</h2>}
				{!error && ready && (
					<Body
						adventure={adventure}
						participants={participants.data}
						candidates={candidates.data}
					/>
				)}
			</div>
		</Modal>
	);
}
