import React, {useEffect} from 'react'
import {useFormContext, useFormState} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'
import Help from 'form/Help'
import {get} from 'utils'
import cn from 'classnames'

const noop = () => {}

const Input = ({
	id,
	name, 
	label, 
	help,
	rules,
	className,
	outerClassName,
	type = 'text',
	observe = noop, 
	placeholder = '',
	readOnly: propsReadOnly,
	...props
}) => {
	const {register, readOnly, watch, defaultPlaceholders = {}} = useFormContext()
	const {errors} = useFormState()
	const watched = watch(name, '')

	useEffect(() => observe(watched), [observe, watched])

	return (
		<div className={cn('form-floating', outerClassName)}>
			<input 
				{...register(name, rules)} 
				className={cn('form-control', className, {'is-invalid': get(errors, name)})}
				type={type} 
				name={name}
				id={id || name} 
				placeholder={placeholder || get(defaultPlaceholders, name)}
				readOnly={readOnly || propsReadOnly}
				{...props}
			/>
			{label && (
				<label htmlFor={name}>
					{label}
					<Help help={help}/>
				</label>
			)}
			<ErrorMessage 
				name={name} 
				errors={errors}
				render={({ message }) => <div className="invalid-feedback">{message}</div>}
			/>
		</div>
	)	
}


export default Input