import detectElectron from 'is-electron';
import day from 'dayjs';
import 'dayjs/locale/de';
import dayRelativeTime from 'dayjs/plugin/relativeTime';
import dayUtc from 'dayjs/plugin/utc'; // dependent on utc plugin
import dayTimezone from 'dayjs/plugin/timezone';
day.extend(dayUtc);
day.extend(dayTimezone);
day.extend(dayRelativeTime);
day.locale('de');
day.tz.setDefault('America/New_York');

export {day};
export const isElectron = detectElectron();
export {default as makeId} from './id';
export {contains} from './contains';
export {default as filterBy} from './filterBy';
export {default as redirect} from './redirect';
export {isOwnedOrUncontrolled} from './isOwnedOrUncontrolled';
export {clamp} from './clamp';
export {isTouchesInsideRect} from './isTouchesInsideRect';
export {getMiddleTouchOnElement} from './getMiddleTouchOnElement';
export {getMiddleOfLine} from './getMiddleOfLine';
export {getLengthOfLine} from './getLengthOfLine';
export {get, noop, has, map, filter, every, merge, sample, pick} from 'lodash';
export {v4 as uuidv4} from 'uuid';
export {default as qs} from 'qs';

export {default as cn} from 'classnames';

export * from './filter';
export * from './quotes';
export {loadFile} from './loadFile';

export function relativeDate(date) {
	if (day().diff(day(date), 'month') >= 1) {
		return day(date).format('DD.MM.YYYY');
	}

	return day(date).fromNow();
}

export function relativeDuration(date) {
	return day().diff(day(date), 'second');
}

export function isDev() {
	return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}
