import React from 'react'
import {EntityLink} from 'common/Links'
import {SubscribeUserButton, InviteUserButton} from 'common/buttons'
import Avatar from 'common/Avatar'

export function UserItem({as, renderButton, ...props})
{
	const ComponentName = as || 'li'
  const {user} = props
	const {username, fullname} = user

	return (
    <ComponentName
      className="list-group-item"
    >
      <div className="row align-items-center">
        <div className="col-auto">
          <Avatar user={user}/>
        </div>
        <div className="col ml-n2">
          <h4 className="mb-1">
            <EntityLink to={user}>
              {fullname}
            </EntityLink>
          </h4>
          <p className="small text-muted mb-0">
            <EntityLink to={user} className="d-block text-reset text-truncate">
              @{username}
            </EntityLink>
          </p>
        </div>
        {renderButton && renderButton(props)}
      </div>
    </ComponentName>
	)
}

export const UserItemWithSubscription = props => (
  <UserItem 
    {...props}
    renderButton={({user}) => (
      <div className="col-auto">
          <SubscribeUserButton user={user}/>
      </div>
    )}
  />
)

export const UserItemWithParticipation = props => (
  <UserItem
    {...props}
    renderButton={({user, adventure}) => (
      <div className="col-auto">
          <InviteUserButton user={user} adventure={adventure}/>
      </div>
    )}
  />
)

export function AdventureItem({adventure})
{
	return (
		<p>Working on it</p>
	)
}