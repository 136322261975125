import React from 'react'
import {withServiceWorkerUpdater} from '@3m1/service-worker-updater'
import {Button} from 'common/buttons'

const Updater = (props) => {
  const {newServiceWorkerDetected, onLoadNewServiceWorkerAccept} = props
  return newServiceWorkerDetected ? (
    <div className="bg-primary text-white p-2">
      New version of Thruhiker detected.
      <Button variant="outline-white" onClick={onLoadNewServiceWorkerAccept}>Update now!</Button>
    </div>
  ) : null // If no update is available, render nothing
}

export default withServiceWorkerUpdater(Updater)