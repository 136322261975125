import React from 'react'
import {useFormContext} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'
import {get, cn} from 'utils'

const Textarea = ({
	id,
	name, 
	label, 
	help,
	rules,
	outerClassName,
	placeholder = ''
}) => {
	const {register, readOnly, formState: {errors}} = useFormContext()
	return (
		<div className={cn('form-floating', outerClassName)}>
			{help && <small class="form-text text-muted">{help}</small>}
			<textarea 
				readOnly={readOnly}
				{...register(name, rules)} 
				className={cn('form-control', {'is-invalid': get(errors, name)})}
				id={id || name} 
				placeholder={placeholder}
			/>
			{label && <label htmlFor={name}>{label}</label>}
			<ErrorMessage 
				name={name} 
				errors={errors}
				render={({ message }) => (
					<div className="invalid-feedback">{message}</div>
				)}
			/>
		</div>
	)	
}


export default Textarea