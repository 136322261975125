import React, {forwardRef} from 'react'
import PropTypes from 'prop-types'

export {
  Home          as HomeIcon,
  Image         as PhotoIcon,
  MapPin        as LocationIcon,
  MessageCircle as PostIcon,
  Music         as ActIcon,
  Search        as SearchIcon,
  Settings      as ClientIcon,
  Speaker       as EventIcon,
  User          as PersonIcon,
  Users         as OrganisationIcon,
  Zap           as VenueIcon,
  Info          as StateIcon,
  MoreVertical  as ContextIcon,
  CloudOff      as PendingIcon,
  Lock          as PrivateIcon,
  Send          as SubmitIcon,
} from 'react-feather'

export const Thruhiker = forwardRef(({color='currentColor', size = 24, ...rest}, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
        <circle cx="12" cy="12" r="10"/>
        <path d="M21.1,15.8l-7.1-7.1l-5.5,5.5l-1.8-1.8L2.9,16"/>
  </svg>
))

Thruhiker.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Thruhiker.displayName = 'IconThruhiker'

export const Globe = forwardRef(({color='currentColor', size = 50, ...rest}, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 100 100"
    fill={color}
    stroke="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
<path d="M50.1,0C22.5,0,0,22.3,0,49.9C0,77.5,22.4,100,50,100c27.6,0,50-22.4,50-50C100,22.4,77.7,0,50.1,0z M62.7,5
    c0-0.3,0-0.6,0.4-0.4c0.2,0.1,0.6,0.2,0.4,0.5c-0.1,0.3-0.1,0.6-0.5,0.7C62.6,5.7,62.7,5.3,62.7,5z M61.4,4.4
    c0,0.1-0.2,0.5-0.3,0.5c-0.6,0-0.9-0.4-1.1-1C60.6,4,61.1,3.8,61.4,4.4z M39.7,95.8c-0.1,0.5-0.6,0.1-0.9,0.1
    C20.5,91.6,5.9,75.6,3.3,57c-0.7-4.9-0.6-9.8,0.1-14.7c0.2-1.2,0.9-2.2,0.8-3.5c0-0.5,0.4-1.3,1.2-1.4c0.7-0.1,1.2-0.1,1.3,0.8
    C6.8,38.8,7,39.4,7.2,40c0,0.2,0.1,0.5,0.1,0.5c1.3,0.3,0.8,1.9,1.8,2.3c0.1,0,0.2,0.2,0.2,0.3c0,2.6,1.7,5,0.8,7.7
    c-0.4,1.3,0.2,2.6,0.5,3.9c0.2,0.8,1.1,1.3,1.7,1.8c0.6,0.6,0.9,1.2,1,2.1c0.1,2.3,2,3.8,3,5.6c0,0.1,0.3,0.1,0.5,0.1
    c0.4-0.1,0.2-0.4,0.2-0.7c-0.1-0.7-0.7-1.2-1.2-1.7c-0.7-0.8-1-1.6-0.9-3c1.3,2,2.4,3.7,3.7,5.2c0.1,0.1,0.1,0.4,0.2,0.6
    c0.1,0.6-0.2,1.3,0.4,1.7c1.3,1,2.6,2.6,4.2,2.7c1.9,0.1,3.2,1.5,4.9,1.9c0.6,0.1,1,0.8,1.4,1.4c0.8,1.3,1.9,2.1,3.5,2.1
    c0.8,0,1.4,0.5,1.7,1.3c0.3,0.8-0.2,1.3-0.7,1.7c-0.6,0.5-1.3,1-0.6,1.9c0,0,0,0.1,0,0.1c-1,1.7,0.6,2.4,1.4,3.3
    c0.2,0.3,0.6,0.5,0.6,0.8c0.3,1.7,1.5,2.3,2.9,2.9c1.3,0.6,1.7,2.4,1.2,4.2c-0.3,1,0.2,2.1-0.5,3c-0.2,0.3,0.3,0.6,0.6,0.8
    C40.1,94.9,39.9,95.4,39.7,95.8z M92.2,29.7c-0.3,0.2-0.4-0.1-0.6-0.3c-0.6-1-1.1-1.9-1.7-2.9c-0.3-0.4-0.3-1.1-1.2-0.8
    c-0.5,0.1-0.7-0.8-1.2-1.3c-0.3-0.3-0.3-0.9-1-0.7c-0.4,0.2-1,0.3-0.8,1c0,0.2,0,0.5-0.3,0.5c-1.2,0.3-1,1.1-0.8,2.1
    c0.4,1.8-0.1,3.4-1.8,4.4c-1.1,0.6-1.2,1.3-0.8,2.4c0.3,0.6,0.3,1.2,0,1.9c-0.3,0.7,0,1.2,0.9,1.1c0.7-0.1,1.3-0.9,2.1,0
    c0.9,0.8,1.1,1.7,0.8,2.9c-0.7-0.1-0.6-0.9-1-1.3c-0.3-0.4-0.5-0.8-1.1-0.6c-0.6,0.3-0.3,0.7-0.3,1.1c0.1,0.5,0.2,1,0.3,1.4
    c0.1,0.5,0.3,1-0.6,0.9c-0.4,0-0.5,0.4-0.7,0.7c-0.4,0.8-0.8,1.5-1.3,2.3C81.3,44.7,81,45.1,81,45c-1-0.6-1.4,0.8-2.3,0.7
    c-0.3,0-0.5,0.1-0.6,0.4c-0.1,0.4,0.1,0.5,0.4,0.7c0.5,0.3,1.1,0.4,1.3,1c0.6,2,0.2,2.5-1.9,2.2c-2-0.4-2.2-0.2-1.9,1.8
    c0.1,0.8-0.2,1.6-0.4,2.4c-0.1,0.7-0.1,1.2,0.8,1.2c1.1-0.1,0.9,0.8,1,1.4c0.1,0.6-0.1,1.2-1,1.2c-1,0-1.4,1-1.2,1.5
    c0.5,1.5-0.4,2.1-1.4,2.6c-1.4,0.7-2.2,1.7-2.6,3.1c-0.3,1-0.5,1.7,0.1,2.6c0.4,0.6,0.5,1.1-0.4,1.5c-0.8,0.4-0.7,2.1,0,2.7
    c0.6,0.4,1.3,0.8,1.2,1.8c-0.1,0.7,2.2,1.7,2.9,1.4c0.7-0.3,1.2-0.7,2.1-0.3c0.7,0.3,1.1-0.6,1.7-1c0.5-0.3,1-0.8,1.5-0.1
    c0.4,0.5,0.8,0.1,1.2,0c0.4-0.1,0.7,0,0.9,0.3c0.3,0.4-0.1,0.6-0.3,0.8c-0.2,0.3-0.5,0.5-0.7,0.8c-0.3,0.5-0.5,1.2,0.2,1.5
    c1.4,0.4,0.8,1.4,0.6,2.1c-0.2,0.6-0.6,1.2-1,1.7c-1,1.2-1.3,2.6-1,4c0.2,1-0.3,1.4-1,1.9c-10.7,8.5-22.9,11.6-36.7,9.9
    c1-1.4,2.1-2.2,3.6-2.3c0.6,0,1.1-0.1,1.4-0.7c0.2-0.5,0.6-0.8,1.1-0.5c0.2,0.1,0.6,0.1,0.6,0c0.3-1.1,1.7-1.1,2.1-2.4
    c0.2-0.9,1.2-1.6,2.4-1.6c1.4,0,3.1-1.5,3-2.9c0-1,0.4-1.5,1.1-1.9c0.3-0.2,0.7-0.5,1-0.7c1-0.9,0.7-2.1-0.4-2.6
    c-1.1-0.4-2.1-1-3.3-0.7c-0.4,0.1-0.8-0.2-1.1-0.4c-0.6-0.5-1.2-0.7-1.9-0.7c-0.9,0.1-1.4-0.2-1.5-1.2c-0.1-1.3-1.4-2.2-2.7-2
    c-0.8,0.1-1.4-0.1-2-0.7c-0.5-0.6-1.5-0.7-1.8-1.2c-1.1-1.3-2.4-0.7-3.6-0.7c-1,0-1.5-0.8-2.2-1.3c-0.7-0.5-1.3-0.8-1.6,0.4
    c-0.2,1-1.8,1.7-2.6,1.2c-0.5-0.3-1-0.3-1.5-0.3c-1.2,0-1.9-0.6-1.7-1.8c0.2-1.4-0.7-2-1.7-2.1c-1-0.1-1-0.6-0.9-1.3
    c0-0.4,0-0.7,0.1-1.1c0.1-0.8-0.4-1.2-1-1.4c-0.7-0.2-0.8,0.5-1.1,0.9c-0.3,0.4,0.1,1.1-0.7,1.2c-0.8,0.1-1.5-0.2-2.1-0.7
    c-1.3-1.4-1.2-4.5,0-5.9c0.8-0.8,1.4-0.8,2,0.1c0.4,0.6,0.7,0.8,1.3,0.1c0.7-1,2.5-0.8,3.1,0.2c0.4,0.6,0.8,1.2,1.2,1.8
    c0.3,0.5,0.8,0.7,1.2,0.4c0.4-0.3,0.4-0.8-0.1-1.3c-0.2-0.2-0.4-0.4-0.5-0.6c-0.6-1.7-1.3-2.7,1.1-3.7c1.1-0.4,1.7-1.2,2-2.4
    c0.3-1.5,0.6-3.2,2.6-3.5c0.5-0.1,0.4-0.6,0.4-1c0.1-1.1,1.7-2.5,2.7-2.4c0.7,0,0.3,0.5,0.2,0.8c-0.1,0.3-0.1,0.6,0.2,0.8
    c0.3,0.1,0.6-0.1,0.6-0.3c0.4-1,1.5-0.5,2.1-1.1c0.3-0.3,0.9-0.6,0.7-1.1c-0.2-0.5-0.7-0.1-1.1-0.3c-1-0.3-1.9-0.5-2.1-1.7
    c0-0.3,0-0.7-0.5-0.7c-0.3,0-0.6,0.2-0.8,0.5c-0.8,1-1.4,1.3-2.6,1.3c1-0.6,1.7-1.3,2.1-2.2c0.3-0.6,1.8-0.5,2.3,0.1
    c0.3,0.5,0.7,0.7,1.2,0.5c0.7-0.3,0.3-0.7,0.1-1c-0.1-0.3,0.1-0.6,0.4-0.5c1,0.7,1.7-1.2,2.5-0.3c0.4,0.4-0.2,0.8-0.4,1.1
    c-0.4,0.5-0.7,1-0.5,1.5c0.3,0.6,0.8,0.2,1.2,0.2c0.5,0,0.7,0.3,1,0.6c0.4,0.6,1,0.5,1.6,0.5c0.6,0,0.6-0.4,0.7-0.8
    c-1-0.3-0.4-2.1-1.8-2.1c-0.5,0-0.6-0.3-0.6-0.7c0.1-0.6,0.1-1.1,0.3-1.7c0.3-0.8,0.3-1.4-0.6-1.8c-0.4-0.1-0.5-0.4-0.6-0.8
    c0-0.3-0.1-0.6-0.5-0.6c-1.8-0.4-2-0.6-1.9-2.3c0-0.2-0.1-0.4-0.3-0.6c-0.7-0.7-1.1-1.6-1.4-2.5c-0.1-0.3-0.2-0.7-0.7-0.7
    C40.9,33,41,33.4,41,33.8c0.1,0.6,0,1.1-0.7,1.4c-0.7,0.3-1.2-0.3-1.9-0.2c-0.2,0-0.2-0.4-0.1-0.7c0.4-1.9-0.7-3.4-2.7-3.5
    c-0.1,0-0.2,0-0.3,0c-1.3-0.3-1.8,0.3-1.7,1.6c0,0.8,0.2,1.7-0.6,2.4c-0.4,0.4-0.2,0.8,0.2,1.1c0.9,0.7,1.1,2.1,0.2,2.7
    c-1,0.7-1.1,1.6-0.8,2.5c0.2,0.8,0,1.5-0.6,1.6c-0.6,0-1.4-0.4-1.3-1.4c0-0.3,0-0.6,0-0.9c0.1-1.2-0.3-1.7-1.5-1.3
    c-0.4,0.1-0.7-0.1-0.9-0.3c-0.5-0.6-1.2-0.8-1.9-1.1c-1.1-0.5-1.6-1.8-2.3-2.8c-0.3-0.5,0-1.2,0.4-1.8c1-1.5,2.1-2.8,3.5-4
    c0.4-0.4,0.5-1.1,0.7-1.8c0.5,0.4,0.4,1,0.1,1.4c-0.3,0.5-0.2,1,0.2,1.1c0.4,0.2,1.1,0.5,1.4-0.2c0.2-0.4,0.4-0.3,0.6-0.1
    c0.4,0.3,0.8,0.3,1.1,0c0.3-0.4,0-0.8-0.3-1.2c-0.4-0.5-1.4-0.6-0.8-1.6c0.3-0.6,0.4-1.2,1-1.6c0.7-0.5,0.4-3-0.4-3.2
    c-0.7-0.3-1.4-0.5-1.5,0.7c0,0.7-0.8,1.1-0.8,1.8c0,0.2-0.4,0.2-0.5,0c-0.1-0.1-0.1-0.3-0.1-0.5c-0.1-0.4,0.3-1.2-0.4-1.4
    c-0.7-0.1-0.3,0.9-0.8,1.2c-0.6-0.8-0.8-1.7-0.8-2.7c0-0.4,0.1-1-0.4-1.3c-0.3-0.1-0.7-0.1-0.8,0.3c-0.2,0.6-0.9,1.1-0.4,1.8
    c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.3,0.3,0.6,0.1,0.8c-0.3,0.4-0.6-0.1-0.8-0.2c-1-0.5-1.3-0.4-1.3,0.8c0,1-1.1,1.8-1.9,1.6
    c-0.4-0.1-0.6-0.3-0.7-0.7c-0.1-0.3,0.1-0.5,0.3-0.7c0.6-0.7,2-1.2,0.6-2.6c-0.5-0.5-0.6-1.7,0-2.5c0.3-0.4,0.4-0.8,0-1
    c-0.3-0.1-0.9,0.1-1,0.6c-0.2,0.7-0.4,1.4-0.6,2.2c-0.6-0.4-1.1-0.7-1.6-1c-1.8-1.2-3.8-0.6-4.6,1.5c0,0,0,0.1,0,0.1
    c0,0.6-0.7,1.3,0,1.7c0.7,0.4,1-0.6,1.4-1c0.4,0.8,0.9,1.4,1.8,0.6c0.3-0.2,0.6-0.2,0.7,0c0.2,0.3-0.1,0.6-0.3,0.7
    c-0.2,0.1-0.4,0.1-0.6,0.2c-0.4,0.1-0.9,0-1,0.4c-0.1,0.6,0.6,0.4,0.8,0.7c0.4,0.6,0.8,0.7,1.6,0.6c0.7,0,0.3,0.9,0.7,1.4
    c-0.6-0.1-1-0.6-1.6-0.5c-0.6,0.1-0.9,0.5-1.4,0.7c-0.4-0.5,0.7-1.2-0.3-1.5c-1-0.3-2-0.4-2.9,0c-0.9,0.4-2,0.5-2.4,1.7
    c-0.1,0.4-1,0.3-1.7,0.2c2.2-3.9,4.8-7.3,7.8-10.4c0.4-0.4,0.5-0.2,0.9,0.1c1.7,1.1,2.3,1.4,3.4,0c0.3-0.4,0.7-0.2,1-0.5
    c0.8-0.7,0.9-1.4,0.1-2c-0.4-0.3-0.7-0.1-0.7,0.2c-0.3,2.1-1.1,0.8-1.7,0.2c-1,0-1.1,1.2-2.2,1.1c0.7-0.6,1.3-1.1,1.9-1.7
    c3.2-2.7,6.7-5,10.7-6.9c-0.4,0.8-1.1,1.3-0.6,2.2c0.5,0.8,0.8,0.8,1.4,0.2c0.1-0.2,0.2-0.6,0.6-0.3c0.2,0.1,0.2,0.4,0.1,0.6
    c-0.1,0.3-0.1,0.6-0.2,0.8c-0.2,0.4-0.8,0.7-0.3,1.1c0.4,0.3,1,0.3,1.4,0c0.7-0.6,1.4-0.6,2.3-0.3c0.6,0.1,1.6,0.1,1.5-1.1
    c0-0.4,0.1-0.8,0.4-0.9c1.1-0.2,1.4-1,1.7-1.9c0.3-0.7,0.9-1.2,1.7-1.5c0.9-0.3,1.7-1,2.2-1.8c0.3-0.4,2.2-1,2.5-0.7
    c0.2,0.1,0.3,0.4,0.1,0.6c-0.3,0.3-0.7,0.5-1.1,0.7C43,5.1,41,4.7,42.9,6c0.3,0.2,0,0.9-0.4,0.8c-1.2-0.4-1.9,0.7-2.9,0.9
    c-0.3,0.1-0.6,0.2-0.4,0.6c0.1,0.3,0.2,0.6,0.6,0.6c0.6,0,1.1,0,1.7,0c0.2,0,0.4,0,0.5,0.3c0,0.3-0.1,0.5-0.4,0.5
    c-0.6,0-1.1,0-1.7,0.1c-0.3,0-0.8,0.3-0.6,0.4c0.7,0.4,0.9,1.6,2,1.3c0.5-0.1,1.1-0.1,1.6-0.3c1.5-0.6,2.6,0.1,3.7,1.2
    c1.1,1.1,1.5,2.7,1.2,3.7c-0.6,1.8-0.5,2,1.3,1.5c0.4-0.1,1,0.5,0.9,1.1c-0.1,0.7-0.6,0.4-1,0.1c-0.4-0.3-0.8-0.1-0.9,0.4
    c-0.1,0.7-0.2,1.4,0.4,1.8c0.4,0.2,1.1,0.1,1.3-0.5c0.1-0.3,0.1-0.8,0.6-0.7c0.4,0.2,0,0.6,0,0.9c0,0.6-0.1,1.1-0.8,1.4
    c-0.8,0.3-1.5,2.5-0.9,3c0.6,0.5,0.7,1,0.7,1.5c0,0.8,0.6,0.7,1.1,0.6c-0.2,0.8-0.7,1.5-0.3,2.3c0.2,0.5,0.2,1.1,0.9,1.1
    c0.3,0,0.4,0.3,0.5,0.6c0,0.1,0,0.2,0.1,0.3c1,0.6,2,1.2,3.1,1.8c0.1,0,0.4-0.2,0.4-0.3c-0.1-1,0.7-1.7,1.1-2.5
    c0.7-1.4,0.5-2.8,0.9-4.1c0.2-0.7,0.6-0.6,1-0.6c1.5,0,2.9-0.9,3.4-2.3c0.2-0.6,0.6-0.5,1-0.6c0.9-0.4,2.2,0.3,2.7-1.1
    c0-0.1,0.2-0.1,0.4-0.1c1.7-0.2,2.4-1.5,1.5-3c-0.5-0.8-0.1-1.8-0.5-2.6c-0.1-0.2,0-0.7,0.4-0.7c1,0,1-0.5,0.6-1.2
    c-0.1-0.3,0-0.3,0.2-0.2c0.5,0.2,0.6,0,0.7-0.4c0.1-0.6-0.3-0.6-0.7-0.7c-1-0.3-1.5-0.9-2.2-1.7c0.4-0.4,1.3,0.1,1.3-0.7
    c0-0.7-0.7-0.6-1-0.9c-0.3-0.3-1.1-0.3-1-0.9c0.1-0.7,0-1.6,1-1.8c0.3-0.1,0.7-0.3,0.6-0.4c-0.1-1.1,0.6-0.5,0.8-0.4
    c5.7,2.4,10.8,5.6,15.3,9.9c3.7,3.6,6.8,7.7,9.2,12.3c0.1,0.2,0.2,0.4,0.3,0.6C92.5,29.3,92.5,29.5,92.2,29.7z"/>
  </svg>
))

Globe.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Globe.displayName = 'IconGlobe'

