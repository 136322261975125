export function getMiddleOfLine(point1, point2) {
    return {
        clientX:
            Math.min(point2.clientX, point1.clientX) +
            Math.abs(point2.clientX - point1.clientX) / 2,
        clientY:
            Math.min(point2.clientY, point1.clientY) +
            Math.abs(point2.clientY - point1.clientY) / 2,
    };
}
