import React from 'react'

export function ModalHeader({subtitle, title}) {
  return (
    <div>
      <h2>
        {subtitle && (
          <small className="d-block mb-2 text-muted">{subtitle}</small>
        )}
        {title}
      </h2>
    </div>
  )
}