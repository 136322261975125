import React from 'react'

export function withDisplayCondition(WrappedComponent, condition)
{
	function WithDataQuery(props) {
		if (!condition(props)) {
			return null
		}

		return (
			<WrappedComponent {...props} />
		)
	}

	WithDataQuery.displayName = `withDisplayCondition(${WrappedComponent.name})`

	return WithDataQuery
}