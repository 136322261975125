import React, {useState, useEffect} from 'react'

const OnlineStatusContext = React.createContext(true);

export const OnlineStatusProvider = ({children}) => {
  const [onlineStatus, setOnlineStatus] = useState(navigator.onLine)

  useEffect(() => {
    const offline = () => setOnlineStatus(false)
    const online = () => setOnlineStatus(true)

    window.addEventListener("offline", offline)
    window.addEventListener("online", online)

    return () => {
      window.removeEventListener("offline", offline)
      window.removeEventListener("online", online)
    }
  }, [])

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>
      {children}
    </OnlineStatusContext.Provider>
  )
}

export const OnlineStatusConsumer = OnlineStatusContext.Consumer
export default OnlineStatusContext