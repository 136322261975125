import rng from 'uuid/dist/rng'

const CHARS = '0123456789abcdefghijklmnopqrstuv'
const ID_MAP = {
	User:             0,
	Organisation:     1,
	Adventure:        5,
	Activity:         6,
	Track:           10,
	Route:           20,
	Poi:             30,
	Location:        31,
	Subscription:    35,
	Participation:   40,
	Membership:      45,
	Gear:            50,
	Backpack:        55,
	Manufacturer:    60,
	Post:            80,
	Comment:         85,
	Like:            90,
	Image:          100,
	Foto:           110,
	ApiClient:      200
}

function base32encode(data)
{
	const length = data.length
  let result = ''
  let a = 0
  let b = 0

  for (var i = 0; i < length; i++) {
    a = (a << 8) | data.charCodeAt(i);
    b += 8

    while (b > 4) {
      b -= 5
      result += CHARS.charAt((a & (0b11111 << b)) >> b)
    }
  }

  if (b) {
    result += CHARS.charAt((a << (5 - b)) & 0b11111)
  }

  return result
}

export default function makeId(entityName)
{
	if (!ID_MAP[entityName]) {
		throw new Error(`Unknown entity ${entityName}`)
	}

	const entityByte = ID_MAP[entityName]
	let rnds = rng()

	// UUID v4 compatible
	rnds[6] = (rnds[6] & 0x0f) | 0x40;
  rnds[8] = (rnds[8] & 0x3f) | 0x80;

  const idString = rnds.reduce((s, i) => String.fromCharCode(i) + s, String.fromCharCode(entityByte))
  return base32encode(idString)
}