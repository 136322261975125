import React from 'react'
import {Modal} from 'common/modals'
import {Button} from 'common/buttons'
import Form, {Input} from 'form'

import {useState, useDebounce, useQuery, useQuote} from 'hooks'
import {queryGeocode} from 'graph'

export function PostLocationModal(props)
{
	const {close, location, setLocation} = props;

	const [keyword, setKeyword] = useState('')
	const debouncedKeyword = useDebounce(keyword)
	const {data} = useQuery(queryGeocode, {
		variables: {
			keyword: debouncedKeyword
		},
		skip: debouncedKeyword === ''
	})

	const titleQuote = useQuote('searchLocation')

	const selectCustomLocation = customLocation => {
		setLocation(customLocation)
		close()
	}

	const removeCurrentLocation = () => {
		setLocation(null)
		close()
	}


	return (
		<Modal>
			<Form>
				<div className="modal-body">
					<div>
		      	<h2>
		      		<small className="text-muted">Search location</small><br/>
		      		{titleQuote.text}
		      	</h2>
		      </div>
		      <Input
		      	name="keyword"
		      	label="Where are you?"
		      	placeholder="Search"
		      	observe={setKeyword}
		      />
		    </div>
		    <div className="list-group list-group-flush">
			    {data && data.geocode.map(location => (
			    	<button 
			    		key={location.hash}
			    		type="button" 
			    		className="px-4 list-group-item list-group-item-action" 
			    		onClick={() => selectCustomLocation(location)} 
			    	>
			    		{location.displayName}
			    	</button>
			    ))}
		    </div>
		    <div className="modal-footer">
			    {location && (
			    	<Button
			    		variant="outline-primary"
			    		onClick={removeCurrentLocation}
			    	>
			    		Remove current location
			    	</Button>
			    )}
		      <Button variant="outline-secondary" type="reset" onClick={() => close()}>Cancel</Button>
		    </div>
		  </Form>
		</Modal>
	)
}