import React from 'react'
import {clearCacheStore} from 'services/cache'

export default function BetaAlert()
{
	const onClick = e => {
		e.preventDefault()
		e.stopPropagation()

		clearCacheStore()
	}

	return (
		<div className="bg-success text-light p-2">
			Thruhiker<sup>Next</sup> is an experimental pre release - Things may not work. If the application cache becomes inconsistent you can try to <a className="alert-link link-light" href="#clear-cache" onClick={onClick}>clear it</a>.
		</div>
	)
}