import styled from 'styled-components'

export const Slide = styled.div`
  width: 100%;
  height: auto;
  display: block;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`