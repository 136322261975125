import React from 'react'
import {Globe} from 'common/Icons'

export function LoadingEllipsis({loading}) {
	if (!loading) {
		return null
	}

	return (
		<div className="lds-loading">
			<div className="lds-ellipsis">
				<div/>
				<div/>
				<div/>
				<div/>
			</div>
		</div>
	)
}

export function LoadingPage({loading}) {
	if (!loading) {
		return null
	}

	return (
		<div className="loading-globe">
			<Globe/>
		</div>
	)
}
