import React from 'react';
import PropTypes from 'prop-types';
import {animated, interpolate} from 'react-spring';
import {useZoom} from 'hooks';
import {Slide as StyledSlide} from './Slide.css';

const defaultProps = {
  initialSlide: 0,
  maxScale: 4,
  minScale: 1,
  slideIndicatorTimeout: 5000,
  activeDotColor: '#4e99e9',
  dotColor: '#dadbdc',
}

const AnimatedSlide = animated(StyledSlide);

export function Slide({children, onScale, minScale, maxScale}) {
    const [element, scale, translateX, translateY, middleTouchOnElement] =
        useZoom({
            minScale,
            maxScale,
            onScale,
        });

    return (
        <AnimatedSlide
            ref={element}
            style={{
                transform: interpolate(
                    [scale, translateX, translateY],
                    (sc, x, y) =>
                        `translate3d(${x}px, ${y}px, 0) scale3d(${sc}, ${sc}, 1)`
                ),
                transformOrigin: middleTouchOnElement.interpolate(
                    (x, y) => `${x}px ${y}px 0`
                ),
            }}
        >
            {children}
        </AnimatedSlide>
    );
}

Slide.propTypes = {
    children: PropTypes.node.isRequired,
    onScale: PropTypes.func,
    minScale: PropTypes.number,
    maxScale: PropTypes.number,
};

Slide.defaultProps = {
    onScale: undefined,
    maxScale: defaultProps.maxScale,
    minScale: defaultProps.minScale,
};
