import React from 'react';
import Form, {Input, Password} from 'form';
import {Link} from 'common/Links';

import {useMe, useQueryParams, useMutation} from 'hooks';
import {mutationLogin, queryMe, queryUserPage} from 'graph';
import {redirect} from 'utils';

function Header() {
    const {reason} = useQueryParams();

    function getTitle() {
        switch (reason) {
            case 'account_required':
                return 'The action you where performing requires to sign-up';
            default:
                return 'Share your adventures with family and friends';
        }
    }

    return (
        <div className="row text-center my-4">
            <div className="col">
                <h1 className="display-3">
                    Sign in
                    <br />
                    <span className="display-4 text-muted fw-lighter">
                        {getTitle()}
                    </span>
                </h1>
            </div>
        </div>
    );
}

function Body() {
    const {return_to} = useQueryParams();
    const me = useMe();

    const [login, {client, loading}] = useMutation(mutationLogin, {
        awaitRefetchQueries: true,
        refetchQueries({data}) {
            if (!data.loginUser.user) {
                return [];
            }

            return [
                {
                    query: queryMe,
                },
                {
                    query: queryUserPage,
                    variables: {
                        username: data.loginUser.user.username,
                    },
                },
            ];
        },
    });

    const onSubmit = (input) =>
        login({
            variables: {
                input,
            },
        }).then(() => {
            console.debug('Resetting store');
            client.resetStore();
            console.log(`Redirecting to ${return_to || '/me'}`);
            redirect(return_to || '/me');
        });

    return (
        <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
                {!me && (
                    <Form onSubmit={onSubmit}>
                        <Input
                            name="username"
                            label="Username or Email Address"
                            placeholder="name@address.com"
                            rules={{
                                required:
                                    'Please enter your username or email address',
                            }}
                            autoComplete="off"
                        />
                        <Password
                            name="password"
                            label="Password"
                            placeholder="Enter your password"
                            autoComplete="off"
                            hint
                            rules={{
                                required: 'Please enter your password',
                            }}
                            outerClassName="mt-3"
                        />
                        <div className="d-grid my-3">
                            <button
                                type="submit"
                                disabled={loading}
                                className="btn btn-lg btn-primary"
                            >
                                Sign in
                            </button>
                        </div>
                        <div className="text-center">
                            <small className="text-muted text-center">
                                Don't have an account yet?{' '}
                                <Link to="sign-up">Sign up</Link>.
                            </small>
                        </div>
                    </Form>
                )}
                {me && <p>You are sign-in as {me.firstname}</p>}
            </div>
        </div>
    );
}

export default function SignIn() {
    return (
        <div className="container">
            <Header />
            <Body />
        </div>
    );
}
