import React from 'react'
import {useRef, useState, useEffect} from 'hooks'
import {Map as LMap, TileLayer, Marker, DivIcon} from 'leaflet'
import {MarkerClusterGroup} from 'leaflet.markercluster'
import {Button} from 'common/buttons'
import {cn} from 'utils'

export function useMap(mapRef, config) {
  const [map, setMap] = useState(null)
  const [tileLayer, setTileLayer] = useState(null)
  const [markerLayer, setMarkerLayer] = useState(null)

  useEffect(() => {
    if (mapRef.current !== null && map === null) {
      const instance = new LMap(mapRef.current, config)

      // Create tile layer
      setTileLayer(
	      new TileLayer('https://tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=ca7f4d78452e4572957f6efa0684776f', {
		      id: 'thunderforest',
		      attribution: 'Tunderforest'
		    }).addTo(instance)
		  )

	    // Create marker layer
	    setMarkerLayer(
	    	new MarkerClusterGroup({
	    		maxClusterRadius: 60,
	    		iconCreateFunction(cluster) {
	    			return new DivIcon({
							className: 'map-marker-icon map-marker-icon-cluster',
							iconSize: [32, 32],
							html: '<div>' + cluster.getChildCount() + '</div>'
						})
	    		}
	    	}).addTo(instance)
	    )

      if (config.center != null && config.zoom != null) {
        instance.setView(config.center, config.zoom)
      } else if (config.bounds != null) {
        instance.fitBounds(config.bounds, config.boundsOptions)
      }
      if (config.whenReady != null) {
        instance.whenReady(config.whenReady)
      }
      setMap(instance)
    }
  }, [mapRef, map, config])

  return [map, {tileLayer, markerLayer}]
}

const PostIcon = new DivIcon({
	className: 'map-marker-icon map-marker-icon-post',
	iconSize: [32, 32],
	html: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-circle"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>'
})

export const makePostMarker = point => new Marker(point, {
	icon: PostIcon
})

export default function Map({adventure})
{
	const mapRef = useRef()
	const [map, {markerLayer}] = useMap(mapRef, {
		bounds: [
			adventure.map.bounds.southWest,
			adventure.map.bounds.northEast
		]
	})

	useEffect(() => {
		if (map && markerLayer) {
			markerLayer.clearLayers()
			adventure.map.points.map(point => makePostMarker(point).addTo(markerLayer))
		}
	}, [map, markerLayer, adventure.map.points])

	return (
    <div ref={mapRef} />
	)
}

export function MapCard({title, label, ratio, adventure, toggle}) 
{
	// There is no location information for this adventure
	if (!adventure.map.bounds) {
		return null
	}

	return (
    <div className="card">
      <div className="card-header">
      	<div className="row align-items-center">
	      	<div className="col">
		        <h4 className="card-header-title">
		          {title}
		        </h4>
		      </div>
		      <div className="col-auto">
		      	<Button variant="white" onClick={toggle}>{label}</Button>
		      </div>
		    </div>
      </div>
      <div className={cn('ratio', ratio)}>
      	<Map adventure={adventure} />
      </div>
    </div>
	)
}

export function SmallMap(props)
{
	return (
		<MapCard
			{...props}
			title="Map"
			label="Full size"
			ratio="ratio-1x1"
		/>
	)
}

export function LargeMap(props)
{
	return (
    <MapCard
    	{...props}
    	title="Map"
    	label="Small size"
    	ratio="ratio-16x9"
    />
	)
}