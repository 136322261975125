import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {animated, useTransition} from 'react-spring';
import {SlideIndicator as StyledSlideIndicator} from './SlideIndicator.css';

const defaultProps = {
    initialSlide: 0,
    maxScale: 4,
    minScale: 1,
    slideIndicatorTimeout: 5000,
    activeDotColor: '#4e99e9',
    dotColor: '#dadbdc',
};

const AnimatedSlideIndicator = animated(StyledSlideIndicator);

export function SlideIndicator({
    currentSlide,
    inFront,
    slideIndicatorTimeout,
    totalSlides,
}) {
    const [isVisible, setVisible] = useState(true);

    useEffect(() => {
        if (slideIndicatorTimeout !== null) {
            const timer = setTimeout(() => {
                setVisible(false);
            }, slideIndicatorTimeout);
            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const transitions = useTransition(isVisible, {
        from: {opacity: 1},
        enter: {opacity: 1},
        leave: {opacity: 0},
    });


    if (totalSlides < 2) {
        return null;
    }

    return (
        <>
            {transitions(
                (style, item, key, props) =>
                    item && (
                        <AnimatedSlideIndicator
                            key={key}
                            inFront={inFront}
                            style={style}
                        >
                            {currentSlide + 1}/{totalSlides}
                        </AnimatedSlideIndicator>
                    )
            )}
        </>
    );
}

SlideIndicator.propTypes = {
    currentSlide: PropTypes.number.isRequired,
    inFront: PropTypes.bool,
    slideIndicatorTimeout: PropTypes.number,
    totalSlides: PropTypes.number.isRequired,
};

SlideIndicator.defaultProps = {
    inFront: true,
    slideIndicatorTimeout: defaultProps.slideIndicatorTimeout,
};
