import React from 'react'
import {useOnlineStatus} from 'hooks'

export default function OfflineAlert()
{
	const isOnline = useOnlineStatus()

	if (isOnline) {
		return null
	}

	return (
		<div className="bg-primary text-white p-2">
			You are currently offline. Your actions will be submitted when you are online again.
		</div>
	)
}