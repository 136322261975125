import React from 'react'
import {useFormContext} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'
import {get, cn} from 'utils'

const Switch = ({
	id,
	name, 
	label, 
	help,
	rules,
	defaultChecked = false
}) => {
	const {register, readOnly, formState: {errors}} = useFormContext()
	return (
		<div className="form-group">
			<label className="mb-1">{label}</label>
			{help && (
				<small className="form-text text-muted">{help}</small>
			)}
			<ErrorMessage 
				name={name} 
				errors={errors}
				render={({ message }) => <div className="invalid-feedback">{message}</div>}
			/>
			<div className="form-check form-switch">
				<input
					{...register(name, rules)}
					type="checkbox"
					readOnly={readOnly}
					disabled={readOnly}
					id={id || name} 
					defaultChecked={defaultChecked}
					className={cn('form-check-input', {'is-invalid': get(errors, name)})}
				/>
				<label className="form-check-label" htmlFor={name}/>
			</div>
		</div>
	)
}

export default Switch