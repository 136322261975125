import React from 'react'
import Button from './Button'

import {useMutation} from 'hooks'
import {mutationInviteParticipator, fragmentNewParticipation} from 'graph'

export default function InviteUserButton({user, adventure, ...props})
{
	const mutationOptions = {
		variables: {
			input: {
				receiverId: user.id,
				adventureId: adventure.id,
			}
		},
		update(cache, {data}) {
			const {participation} = data.inviteParticipator;
			const newParticipationRef = cache.writeFragment({
				data: participation,
        fragment: fragmentNewParticipation, 
        fragmentName: 'NewParticipation'
      });

			cache.modify({
  			id: cache.identify(adventure),
  			fields: {
    			participations({edges, pagination}) {
      			return {
      				pagination: {
      					...pagination,
      					total: pagination.total + 1
      				},
      				edges: [...edges, newParticipationRef]
      			}
      		}
      	}
      })
    },
  };

	const [invite, {loading}] = useMutation(mutationInviteParticipator, mutationOptions) 

	return (
		<Button
			onClick={() => invite()} 
			disabled={loading}
		>
			Invite
		</Button>
	);
}