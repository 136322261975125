import React from 'react'
import {Button} from 'common/buttons'
import {UserItemWithSubscription} from 'common/Items'
import {AddParticipatorModal} from 'common/modals';

import {useQuery, useModal} from 'hooks'
import {queryAdventureParticipations, queryUserParticipations} from 'graph'

function Participation({participation})
{
  const {receiver} = participation
  return (
    <UserItemWithSubscription user={receiver} />
  )
}

function AddParticipatorButton({adventure}) {
  const open = useModal(AddParticipatorModal, {
    adventure
  });

  return (
    <Button variant="outline-primary" onClick={() => open()}>
      Add participator
    </Button>
  );
}

function Participations({participations, adventure})
{
  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">
          Participants
        </h4>
      </div>
      <div className="card-body">
        <div className="list-group list-group-flush my-n3">
          {participations.map(participation => (
            <Participation
              key={participation.id}
              participation={participation}
            />
          ))}
        </div>
      </div>
      {adventure.viewerCanUpdate && (
        <div className="card-footer">
          <AddParticipatorButton adventure={adventure} />
        </div>
      )}
    </div>
  )
}

export function AdventureParticipations({adventure})
{
  const {data, loading} = useQuery(queryAdventureParticipations, {
    variables: {
      id: adventure.id
    }
  })

  return (
    <Participations
      loading={loading}
      participations={data?.adventure.participations.edges || []}
      adventure={adventure}
    />
  )
}

export function UserParticipations({user})
{
  const {data, loading} = useQuery(queryUserParticipations, {
    variables: {
      id: user.id
    }
  })

  return (
    <Participations
      loading={loading}
      participations={data?.user.participations.edges || []}
    />
  )
}