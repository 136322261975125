import {InMemoryCache} from '@apollo/client'
import {persistCache, AsyncStorageWrapper} from 'apollo3-cache-persist'
import localforage from 'localforage'

export const cacheStore = localforage.createInstance({
  name: 'thruhiker',
  storeName: 'cache'
})

export const operationStore = localforage.createInstance({
  name: 'thruhiker',
  storeName: 'operations'
})

export const clearCacheStore = () => cacheStore.clear().then(() => console.log('CacheStore cleared'))
export const clearOperationStore = () => operationStore.clear().then(() => console.log('OperationStore cleared'))

function makeEmptyData() {
  return {
    edges: {},
    pagination: {
      total: 0,
      limit: 10,
      offset: 0,
    },
  };
}

function edgeField(keyArgs=false) {
  return {
    // Don't cache separate results based on
    // any of this field's arguments.
    keyArgs,

    read(existing) {
      if (!existing) {
        return
      }

      return {
        ...existing,
        edges: Object.values(existing.edges)
      }
    },

    // Concatenate the incoming list items with
    // the existing list items.
    merge(existing=makeEmptyData(), incoming, {args, fieldName, readField}) {
      const mergedEdges = args?.offset !== 0 ? {...existing.edges} : {}

      incoming.edges.forEach(edge => {
        mergedEdges[readField('id', edge)] = edge
      })

      const mergedPagination = {
        ...existing.pagination,
        ...incoming.pagination,
        offset: Object.values(mergedEdges).length
      }

      return {
        ...incoming,
        pagination: mergedPagination,
        edges: mergedEdges
      }
    },
  }
}


export async function initCache()
{
	const cache = new InMemoryCache({
	  possibleTypes: {
	    UserOrValidationErrors: ['User', 'ValidationErrors'],
	  },
	  typePolicies: {
	    Query: {
	      fields: {
	        publicFeed: edgeField(),
	      }
	    },
	    Adventure: {
	      fields: {
	        posts: edgeField()
	      }
	    },
      User: {
        fields: {
          posts: edgeField()
        }
      },
      Post: {
        fields: {
          comments: edgeField()
        }
      },
	  }
	})
  
	await persistCache({
	  cache,
	  storage: new AsyncStorageWrapper(cacheStore),
	  debug: true
	})

  return cache
}