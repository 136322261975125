import gql from 'graphql-tag'
import {fragmentPostDetail} from './post'
import {fragmentUserList} from './user'

export const fragmentAdventureDetail = gql`
	fragment AdventureDetail on Adventure {
		id
		title
		description
		privacy
		author {
			id
			fullname
		}
		map {
			bounds {
				southWest {
					lat
					lng
				}
				northEast {
					lat
					lng
				}
				center {
					lat
					lng
				}
			}
			points {
				lat
				lng
			}
		}
		viewerCanRead
		viewerCanUpdate
		viewerCanPost
	}
`

export const fragmentAdventurePosts = gql`
	fragment AdventurePosts on Adventure {
		id
		posts {
			reset @client
		}
	}
`

export const fragmentNewParticipation = gql`
	fragment NewParticipation on Participation {
		id
		receiver {
			...UserList
		}
	}
	${fragmentUserList}
`


export const queryAdventure = gql`
	query adventure($id: ID!) {
		adventure(id: $id) {
			...AdventureDetail
		}
	}
	${fragmentAdventureDetail}
`

export const queryAdventureFeed = gql`
	query adventureFeed($id: ID!, $offset: Int!, $limit: Int!) {
		adventure(id: $id) {
			id
			posts(limit: $limit, offset: $offset) {
				pagination {
					offset
					limit
					total
				}
				edges {
					...PostDetail
				}
			}
		}
	}
	${fragmentPostDetail}
`

export const queryAdventureFeedNew = gql`
	query adventureFeed($id: ID!) {
		adventure(id: $id) {
			posts(limit: 0, offset: 0) {
				pagination {
					total
				}
			}
		}
	}
`

export const queryAdventureParticipations = gql`
	query adventureParticipations($id: ID!) {
		adventure(id: $id) {
			id
			participations {
				pagination {
					offset
					limit
					total
				}
				edges {
					id
					receiver {
						...UserList
					}
				}
			}
		}
	}
	${fragmentUserList}
`

export const mutationCreateAdventure = gql`
	mutation createAdventure($input: CreateAdventureInput!) {
		createAdventure(input: $input) {
			adventure {
				...AdventureDetail
			}
		}
	}
	${fragmentAdventureDetail}
`

export const mutationInviteParticipator = gql`
	mutation inviteParticipator($input: CreateParticipationInput!) {
		inviteParticipator(input: $input) {
			participation {
				id
				receiver {
					...UserList
				}
			}
		}
	}
	${fragmentUserList}
`