import React from 'react'

export default function Help({help})
{
	if (!help) {
		return null
	}

	return (
		<>
			<br/>
			<small className="form-text text-muted">{help}</small>
		</>
	)
}