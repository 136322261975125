import gql from 'graphql-tag'

export * from './adventure'
export * from './comment'
export * from './home'
export * from './post'
export * from './user'

export const queryReverseGeocode = gql`
	query reverseGeocode($point: PointInput!) {
		reverseGeocode(point: $point) {
			hash
			displayName
			point {
				lat
				lng
			}
		}
	}
`

export const queryGeocode = gql`
	query geocode($keyword: String!) {
		geocode(keyword: $keyword) {
			hash
			displayName
			point {
				lat
				lng
			}
		}
	}
`