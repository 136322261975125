import React, {forwardRef} from 'react';
import {cn} from 'utils';

const Button = forwardRef(
	(
		{
			as: Comp = 'button',
			type = 'button',
			size,
			variant = 'primary',
			large,
			className,
			...props
		},
		ref
	) => {
		return (
			<Comp
				ref={ref}
				type={type}
				className={cn(
					'btn',
					'btn-' + variant,
					size && 'btn-' + size,
					className
				)}
				{...props}
			/>
		);
	}
);

export default Button;
