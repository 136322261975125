export function getLengthOfLine(point1, point2) {
    const middlePoint = {
        clientX: point2.clientX,
        clientY: point1.clientY,
    };

    const legX = Math.abs(middlePoint.clientX - point1.clientX);
    const legY = Math.abs(middlePoint.clientY - point2.clientY);

    return Math.sqrt(legX ** 2 + legY ** 2);
}
