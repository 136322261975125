import React from 'react'
import {Modal, ModalHeader, ReportPostModal} from 'common/modals'
import {Button} from 'common/buttons'
import Form, {Hidden, Switch, Input} from 'form'

import {useMutation, useQuote, useModal} from 'hooks'
import {mutationUpdateUserProfile} from 'graph'
import {pick} from 'utils'

export function UpdateUserModal(props)
{
  const {user, close} = props
  const openTest = useModal(ReportPostModal)
  const [doUpdateUserProfile, {loading, error}] = useMutation(mutationUpdateUserProfile, {
    onCompleted(data) {
      close()
    },
    onError(error) {
      console.log('An error occured while updating user profile', error)
    }
  })
  const onSubmit = input => doUpdateUserProfile({
    variables: {
      input
    }
  })
  const titleQuote = useQuote('updateUser')

  return (
    <Modal {...props}>
      <Form 
        onSubmit={onSubmit}
        error={error}
        defaultValues={pick(user, [
          'id',
          'username',
          'firstname',
          'lastname',
          'email',
          'biography',
          'isPrivate'
        ])}
      >
        <div className="modal-body">
          <ModalHeader
            subtitle="Edit User Profile"
            title={titleQuote.text}
          />
          <Hidden
            name="id"
          />
          <Input
            name="username"
            label="Username"
            placeholder="Username"
            rules={{
              required: 'Please enter a username'
            }}
            outerClassName="mb-2"
          />
          <p className="form-text text-muted mb-3">Your username is unique on Thruhiker's network. If you change it, there may be no going back.</p>
          <div className="row">
            <div className="col">
              <Input
                name="firstname"
                label="Firstname"
                placeholder="Firstname"
                rules={{
                  required: 'Please enter your firstname'
                }}
                outerClassName="mb-2"
              />
            </div>
            <div className="col">
              <Input
                name="lastname"
                label="Lastname"
                placeholder="Lastname"
                rules={{
                  required: 'Please enter your lastname'
                }}
                outerClassName="mb-2"
              />
            </div>
          </div>
          <div className="form-text text-muted mb-3">The name by which you are known makes it easier for people to find your account. Therefore, use your full name.</div>

          <Input
            name="email"
            label="E-Mail"
            placeholder="E-Mail"
            rules={{
              required: 'Please enter your e-mail address'
            }}
            outerClassName="mb-3"
          />
          <Input
            name="biography"
            label="Biography"
            placeholder="Biography"
            outerClassName="mb-3"
          />
          <h2 className="mt-4">Privacy</h2>
          <Switch
            name="isPrivate"
            label="Private account"
            help="If you have a private account, only people you've confirmed can see your photos and posts on Thruhiker. Your existing subscribers remain unchanged."
          />
        </div>
        <div className="modal-footer">
          <Button onClick={() => openTest()}>Open Test</Button>
          <Button 
            variant="outline-secondary" 
            type="reset" 
            onClick={close}
          >
            Cancel
          </Button>
          <Button 
            variant="outline-success"
            type="submit"
            disabled={loading}
          >
            Update
          </Button>
        </div>
      </Form>
    </Modal>
  )
}