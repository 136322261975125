import React, {Fragment} from 'react'

export default function TextWithLinebreaks({as, text, ...props}) {
	const Comp = as || 'p'
	return (
		<Comp {...props}>
			{text.trim().split('\n').map((item, key) => {
        return <Fragment key={key}>{item}<br/></Fragment>
      })}
    </Comp>
	)
}