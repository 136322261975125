import React from 'react'

const PostContext = React.createContext({})

export const PostProvider = ({post, children}) => {
	return (
		<PostContext.Provider value={post}>
			{children}
		</PostContext.Provider>
	)
}
export const PostConsumer = PostContext.Consumer
export default PostContext