import gql from 'graphql-tag'
import {fragmentPostDetail} from './post'

export const fragmentUserDetail = gql`
	fragment UserDetail on User {
		id
		createdAt
		updatedAt
		username
		fullname
		firstname
		lastname
		biography
		email
		isPrivate
		profileImage {
			id
			uri(type: THUMBNAIL_LARGE)
		}
		viewerSubscriptionState
		viewerSubscription {
			id
			state
		}
	}
`

export const fragmentUserList = gql`
	fragment UserList on User {
		id
		username
		fullname
		firstname
		lastname
		profileImage {
			id
			uri(type: THUMBNAIL_LARGE)
		}
		viewerSubscriptionState
		viewerSubscription {
			id
			state
		}
	}
`

export const queryMe = gql`
	query me {
			me {
			id
			createdAt
			updatedAt
			username
			fullname
			firstname
			lastname
			biography
			isPrivate
			profileImage {
				id
				uri(type: THUMBNAIL_SMALL)
			}
		}
	}
`

export const queryMePage = gql`
	query mePage {
		me {
			...UserDetail
			viewerCanRead
			viewerCanUpdate
		}
	}
`

export const queryUserPage = gql`
	query userPage($username: String!) {
		user(username: $username) {
			...UserDetail
			viewerCanRead
			viewerCanUpdate
		}
	}
	${fragmentUserDetail}
`

export const queryUserFeed = gql`
	query userFeed($username: String!, $offset: Int!, $limit: Int!) {
		user(username: $username) {
			id
			posts(offset: $offset, limit: $limit) {
				pagination {
					offset
					limit
					total
				}
				edges {
					...PostDetail
				}
			}
		}
	}
	${fragmentPostDetail}
`

export const queryUserFeedNew = gql`
	query userFeedNew($username: String!) {
		user(username: $username) {
			posts(offset: 0, limit: 0) {
				pagination {
					total
				}
			}
		}
	}
`



export const queryUserParticipations = gql`
	query userParticipations($id: ID!, $offset: Int, $limit: Int) {
		user(id: $id) {
			id
			participations(offset: $offset, limit: $limit) {
				pagination {
					offset
					limit
					total
				}
				edges {
					id
					createdAt
					adventure {
						id
						createdAt
						title
						description
					}
				}
			}
		}
	}
`

export const queryUserSubscriptionsSend = gql`
	query userSubscriptionsSend($id: ID!, $offset: Int, $limit: Int) {
		user(id: $id) {
			id
			subscriptionsSend(offset: $offset, limit: $limit) {
				pagination {
					offset
					limit
					total
				}
				edges {
					id
					createdAt
					state
					receiver {
						id
						username
						fullname
						profileImage {
							id
							uri(type: THUMBNAIL_SMALL)
						}
						viewerSubscriptionState
					}
				}
			}
		}
	}
`

export const queryUserSubscriptionsReceived = gql`
	query userSubscriptionsReceived($id: ID!, $offset: Int, $limit: Int) {
		user(id: $id) {
			id
			subscriptionsReceived(offset: $offset, limit: $limit) {
				pagination {
					offset
					limit
					total
				}
				edges {
					id
					createdAt
					state
					sender {
						id
						username
						fullname
						profileImage {
							id
							uri(type: THUMBNAIL_SMALL)
						}
						viewerSubscriptionState
					}
				}
			}
		}
	}
`

export const mutationLogin = gql`
	mutation loginUser($input: LoginUserInput!) {
		loginUser(input: $input) {
			user {
				...UserDetail
			}
			success
			error
		}
	}
	${fragmentUserDetail}
`

export const mutationSignUp = gql`
	mutation signUpUser($input: CreateUserInput!) {
		createUser(input: $input) {
			user {
				id
				username
				fullname
				firstname
				locale
			}
		}
	}
`

export const mutationLogout = gql`
	mutation logoutUser {
		logoutUser {
			success
			error
		}
	}
`

export const mutationUpdateUserProfile = gql`
	mutation updateUserProfile($input: UpdateUserProfileInput!) {
		updateUserProfile(input: $input) {
			user {
				...UserDetail
			}
		}
	}
	${fragmentUserDetail}
`

export const fragmentNewSubscription = gql`
	fragment NewSubscription on Subscription {
		id
		createdAt
		state
		receiver {
			id
			username
			fullname
			viewerSubscriptionState
		}
		sender {
			id
			username
			fullname
		}
	}
`

export const mutationSubscribeUser = gql`
	mutation subscribeUser($input: CreateSubscriptionInput!) {
		subscribeUser(input: $input) {
			subscription {
				...NewSubscription
			}
		}
	}
	${fragmentNewSubscription}
`

export const mutationUnsubscribeUser = gql`
	mutation unsubscribeUser($input: DeleteSubscriptionInput!) {
		unsubscribeUser(input: $input) {
			subscription {
				id
				receiver {
					id
					viewerSubscriptionState
				}
				sender {
					id
				}
			}
		}
	} 
`
