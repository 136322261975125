import React from 'react';
import {FeedNext} from 'common/Feed';
import {AdventureParticipations} from 'common/Participations';
import {Button} from 'common/buttons';
import {LoadingPage} from 'common/Loading';
import Error from 'common/Error';
import {LocationIcon, PhotoIcon} from 'common/Icons';
import {PostPrivacy, ADVENTURE_PRIVACY_MAP} from 'common/Dropdown';
import {SmallMap, LargeMap} from 'pages/adventure/Map';
import {PostLocationModal} from 'modals';

import {
    useMe,
    useQuery,
    useMutation,
    useState,
    useFilePicker,
    useEffect,
    useRef,
    useOnClickOutside,
    useGeolocation,
    useModal,
    useOnlineStatus,
    useParams,
    useApolloClient,
    useCallback
} from 'hooks';
import {
    queryAdventure,
    queryAdventureFeed,
    queryAdventureFeedNew,
    queryReverseGeocode,
    mutationCreatePost,
    fragmentPostDetail,
    //fragmentAdventurePosts
} from 'graph';
import {cn, makeId} from 'utils';
import {withDisplayCondition} from 'common/hoc';

function useLocation(initial = true) {
    // Try to use geolocation
    // when available
    const geolocation = useGeolocation();
    const {latitude: lat, longitude: lng, error} = geolocation;
    const [locationFromGps, setLocationFromGps] = useState(initial);
    const [location, setLocation] = useState();
    const client = useApolloClient();

    useEffect(() => {
        if (locationFromGps && lat && lng) {
            client
                .query({
                    query: queryReverseGeocode,
                    variables: {
                        point: {
                            lat,
                            lng,
                        },
                    },
                })
                .then(({data}) => setLocation(data.reverseGeocode));

            if (!location) {
                setLocation({
                    __typename: 'Location',
                    displayName: `Lat ${lat}, Lng ${lng}`,
                    hash: null,
                    point: {
                        lat,
                        lng,
                    },
                });
            }
        }
    }, [client, location, lat, lng, locationFromGps]);

    const setLocationCallback = useCallback((customLocation, fromGps = false) => {
        setLocationFromGps(fromGps);
        setLocation(customLocation);
    }, []);

    return [
        location,
        {
            error,
            geolocation,
            setLocation: setLocationCallback
        },
    ];
}

function Photo({file, remove}) {
    return (
        <div
            className="post-addon-photo"
            style={{backgroundImage: `url(${file.content})`}}
        >
            <button onClick={remove} className="post-addon-photo-delete">
                <i className="fe fe-x" />
            </button>
        </div>
    );
}

function MakePost({adventure}) {
    const me = useMe();

    // Remove privation dropdown
    // when user leaves the post area
    const [focused, setFocused] = useState(false);
    const frameRef = useRef();
    useOnClickOutside(frameRef, () => setFocused(false));

    const [body, setBody] = useState('');
    const [privacy, setPrivacy] = useState(
        ADVENTURE_PRIVACY_MAP[adventure.privacy].postPrivacy
    );
    const [location, {setLocation, error: locationError}] = useLocation();

    const openLocationModal = useModal(PostLocationModal, {
        location,
        setLocation,
    });

    const [
        openMediaSelector,
        {
          filesContent: mediaUrls, 
          plainFiles: media, 
          clear,
        },
    ] = useFilePicker({
        multiple: true,
        readAs: 'DataURL', // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
        accept: ['image/jpeg', 'image/png'],
        limitFilesConfig: {min: 1, max: 10},
        maxFileSize: 15,
    });

    // Create post mutation
    const [doPost, {loading: posting}] = useMutation(mutationCreatePost, {
        onError: (error) => console.log(error),
        update(cache, {data}) {
            const newPost = data.createPost.post;
            cache.modify({
                broadcast: false,
                id: cache.identify(adventure),
                fields: {
                    posts({pagination = {}, edges = {}}, {readField}) {
                        const newPostRef = cache.writeFragment({
                            data: newPost,
                            fragment: fragmentPostDetail,
                            fragmentName: 'PostDetail',
                        });
                        return {
                            pagination: {
                                ...pagination,
                                total: pagination.total + 1,
                            },
                            edges: {
                                [readField('id', newPost)]: newPostRef,
                                ...edges,
                            },
                        };
                    },
                },
            });
        },
    });
    const submitPost = () => {
        const clientId = makeId('Post');
        const clientCreatedAt = new Date().toISOString();

        doPost({
            variables: {
                input: {
                    clientId,
                    clientCreatedAt,
                    adventureId: adventure.id,
                    type: 'DIARY_ENTRY',
                    privacy,
                    body,
                    media,
                    location: location && {
                        lat: location.point.lat,
                        lng: location.point.lng,
                        alt: location.point.alt,
                    },
                },
            },
            optimisticResponse: {
                createPost: {
                    __typename: 'CreatePostPayload',
                    post: {
                        __typename: 'Post',
                        isPending: true,
                        id: clientId,
                        createdAt: clientCreatedAt,
                        updatedAt: null,
                        type: 'DIARY_ENTRY',
                        day: null,
                        body,
                        author: me,
                        adventure,
                        location: location && {
                            __typename: 'Location',
                            hash: location.hash,
                            displayName: location.displayName,
                        },
                        comments: {
                            __typename: 'AdventurePostCommentsRelation',
                            pagination: {
                                __typename: 'Pagination',
                                offset: 0,
                                limit: 10,
                                total: 0,
                            },
                            edges: [],
                        },
                        fotos: [],
                        viewerCanRead: true,
                        viewerCanUpdate: true,
                        viewerCanDelete: true,
                        viewerHasLiked: false,
                        viewerHasCommented: false,
                    },
                },
            },
        });

        clear();
        setBody('');
        setPrivacy(ADVENTURE_PRIVACY_MAP[adventure.privacy].postPrivacy);
    };

    const removePhotoByIndex = (index) => {
        clear(index);
    };

    return (
        <div className="card">
            <div ref={frameRef} className="card-body">
                <textarea
                    placeholder="What's going on?"
                    className="form-control form-control-flush"
                    value={body}
                    onFocus={() => setFocused(true)}
                    onChange={(e) => setBody(e.target.value)}
                    style={{
                        fontSize: '1.6rem',
                    }}
                />
                {focused && (
                    <div className="row justify-content-between">
                        <div className="col-auto">
                            <PostPrivacy
                                value={privacy}
                                onChange={(value) => setPrivacy(value)}
                            />
                        </div>
                        <div className="col-auto">
                            <Button
                                disabled={posting || (!body && !media.length)}
                                onClick={submitPost}
                            >
                                Make post
                            </Button>
                        </div>
                    </div>
                )}
                {mediaUrls.length > 0 && (
                    <div className="row mt-2">
                        {mediaUrls.map((file, index) => (
                            <div key={index} className="col-auto pe-0">
                                <Photo
                                    file={file}
                                    remove={() => removePhotoByIndex(index)}
                                />
                            </div>
                        ))}
                    </div>
                )}
                {location && (
                    <p className="my-2 text-muted">
                        <LocationIcon size={13} />
                        &nbsp;
                        {location.displayName}
                    </p>
                )}
                <hr />
                <ul className="post-addons">
                    <li
                        className={cn('post-addon-item', {
                            active: media.length > 0,
                        })}
                    >
                        <button
                            onClick={openMediaSelector}
                            className="btn btn-link"
                        >
                            <PhotoIcon size={25} />
                        </button>
                    </li>
                    <li
                        className={cn('post-addon-item', {
                            disabled: locationError,
                            active: location,
                        })}
                    >
                        <button
                            onClick={() => openLocationModal()}
                            disabled={locationError}
                            className="btn btn-link"
                        >
                            <LocationIcon size={24} />
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
}

const ConditionalMakePost = withDisplayCondition(
    MakePost,
    ({adventure}) => adventure.viewerCanPost
);

export function AdventureMeta({adventure}) {
    return (
        <div className="card">
            <div className="card-body">
                <ul className="list-group list-group-flush">
                    <li className="list-group-item"></li>
                </ul>
            </div>
        </div>
    );
}

export function AdventureFeed({id}) {
    const resolve = (data) => data?.adventure.posts;
    return (
        <FeedNext
            query={queryAdventureFeed}
            queryMonitor={queryAdventureFeedNew}
            resolveConnection={resolve}
            variables={{
                id,
            }}
        />
    );
}

export function Adventure() {
    const {id} = useParams();
    const [smallMap, setSmallMap] = useState(true);
    const isOnline = useOnlineStatus();
    const {data, loading, error} = useQuery(queryAdventure, {
        fetchPolicy: isOnline ? 'cache-and-network' : 'cache-only',
        nextFetchPolicy: 'cache-only',
        variables: {
            id,
        },
    });

    if (!data) {
        if (!isOnline) {
            return (
                <div className="row text-center my-4">
                    <div className="col">
                        <h1 className="display-3">
                            Adventure not available
                            <br />
                            <span className="display-4 text-muted fw-lighter">
                                No data cached for offline usage
                            </span>
                        </h1>
                    </div>
                </div>
            );
        }

        if (error) {
            return <Error error={error} />;
        }

        if (loading) {
            return <LoadingPage />;
        }
    }

    const {adventure} = data;

    return (
        <>
            <div className="row text-center my-4">
                <div className="col">
                    <h1 className="display-3">
                        {adventure.title}
                        <br />
                        <span className="display-4 text-muted fw-lighter">
                            {adventure.description}
                        </span>
                    </h1>
                </div>
            </div>
            <ConditionalMakePost adventure={adventure} />
            <div className="row">
                <div className="col-md-4 order-first order-md-last">
                    <AdventureParticipations adventure={adventure} />
                    {smallMap && (
                        <SmallMap
                            adventure={adventure}
                            toggle={(e) => setSmallMap(false)}
                        />
                    )}
                </div>
                <div className="col-md-8 order-last order-md-first">
                    {!smallMap && (
                        <LargeMap
                            adventure={adventure}
                            toggle={(e) => setSmallMap(true)}
                        />
                    )}
                    <AdventureFeed id={id} />
                </div>
            </div>
        </>
    );
}

export default Adventure;
