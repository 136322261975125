import React from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/airbnb.css'
import {German} from "flatpickr/dist/l10n/de.js"

import {get, day, cn, noop} from 'utils'
import {useEffect} from 'hooks'

function prepareOnChange(value, range) {
	if (value.length === 0) {
		return null
	}

	return range 
		? {
			from: value[0] && day(value[0]).format(),
			to: value[1] && day(value[1]).format()
		}
		: day(value[0]).format()
}

function prepareValue(value, range) {
	if (!value) {
		return null
	}
	
	return range
		? [
			value.from && day(value.from).toDate(),
			value.to && day(value.to).toDate()
		]
		: value && day(value).toDate()
}

const DatePicker = ({
	id,
	name, 
	label, 
	help,
	rules,
	observe = noop, 
	options = {},
	...props
}) => {

	const {control, errors, watch} = useFormContext()
	const range = options.mode === 'range'

	const watched = watch(name, null)

	useEffect(() => observe(watched), [observe, watched])

	return (
		<div className="form-group">
			<label htmlFor={name}>{label}</label>
			<Controller
				render={({onChange, onBlur, value}) => (
					<Flatpickr
						value={prepareValue(value, range)}
						onClose={data => onChange(prepareOnChange(data, range))}
						className={cn('form-control', {'is-invalid': get(errors, name)})} 
						options={{locale: German, ...options}} 
						{...props } 
					/>
				)}
				name={name}
				control={control}
				rules={rules}
			/>
			<ErrorMessage 
				name={name} 
				errors={errors}
				render={({ message }) => <div className="invalid-feedback">{message}</div>}
			/>
		</div>
	)
}

export const Date = ({options = {}, ...props}) => (
	<DatePicker {...props} options={{dateFormat: "Y-m-d", ...options}}/>
)

export const DateRange = ({options = {}, ...props}) => (
	<DatePicker {...props} options={{dateFormat: "Y-m-d", mode: 'range', ...options}}/>
)

export const Time = ({options = {}, ...props}) => (
	<DatePicker {...props} options={{enableTime: true, noCalendar:true, dateFormat: "H:i", time_24hr: true, ...options}}/>
)
export const DateTime = ({options = {}, ...props}) => (
	<DatePicker {...props} options={{enableTime: true, dateFormat: "Y-m-d H:i", time_24hr: true, ...options}}/>
)