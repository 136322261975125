import history from 'services/history'
import {makeEntityUrl} from 'common/Links'


export default function redirect(to) {
	if (typeof to === 'string' || to instanceof String) {
		history.push(to)
	}
	else {
		const url = makeEntityUrl(to)
		history.push(url)
	}
}
