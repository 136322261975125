import React from 'react'
import {useState, useEffect} from 'hooks'

function withDelayedDisplay(WrappedComponent, delay=2000)
{
	function WithDelayedDisplay(props) {
		const {isPending} = props
		const [show, setShow] = useState(false)

		useEffect(() => {
			if (isPending) {
				const timer = setTimeout(() => setShow(true), delay)
				return () => clearTimeout(timer)
			}

			setShow(false)
		}, [isPending])

		if (!show) {
			return null
		}

		return (
	    <WrappedComponent {...props} />
		)
	}

	WithDelayedDisplay.displayName = `withDelayedDisplay(${WrappedComponent.name})`

	return WithDelayedDisplay
}

function BaseCommentPendingAlert() {
	return (
    <div className="text-muted">
      <small><i className="fe fe-cloud-off"/>&nbsp;This comment is still pending and will be send when you are online again</small>
    </div>
	)
}

function BasePostPendingAlert() {
	return (
    <div className="py-2 px-4 text-muted">
      <small><i className="fe fe-cloud-off"/>&nbsp;This post is still pending and will be send when you are online again</small>
    </div>
	)
}

export const CommentPendingAlert = withDelayedDisplay(BaseCommentPendingAlert, 1000)
export const PostPendingAlert = withDelayedDisplay(BasePostPendingAlert, 1000)

