import React from 'react'
import {Modal} from 'common/modals'
import {Button} from 'common/buttons'
import Form, {Input, Textarea} from 'form'
import {AdventurePrivacy} from 'common/Dropdown'

import {useState, useMutation, useQuote} from 'hooks'
import {mutationCreateAdventure} from 'graph'
import {redirect} from 'utils'

export function CreateAdventureModal(props)
{
	const {close} = props

	const [privacy, setPrivacy] = useState('PUBLIC')
	const [doCreateAdventure, {loading}] = useMutation(mutationCreateAdventure, {
		onCompleted(data) {
			close()
			redirect(data.createAdventure.adventure)
		},
		onError(error) {}
	})
	const onSubmit = input => doCreateAdventure({
		variables: {
			input: {
				...input,
				privacy
			}
		}
	})

	const titleQuote = useQuote('createAdventure')
	return (
		<Modal {...props}>
			<Form onSubmit={onSubmit}>
				<div className="modal-body">
					<div>
		      	<h2>
		      		<small className="text-muted">Create Adventure</small><br/>
		      		{titleQuote.text}
		      	</h2>
		      </div>
		      <Input
		      	name="title"
		      	label="Give a meaningful title to your adventure"
		      	placeholder="Give a meaningful title to your adventure"
		      	rules={{
		      		required: 'Please enter a title'
		      	}}
		      	outerClassName="mb-2"
		      />
		      <Textarea
		      	name="description"
		      	label="Write a small description, what this adventure is about"
		      	placeholder="Write a small description, what this adventure is about"
		      	rules={{
		      		required: 'Please enter a description'
		      	}}
		      	outerClassName="mb-2"
		      />
		      <AdventurePrivacy value={privacy} onChange={setPrivacy} />
		    </div>
		    <div className="modal-footer">
		      <Button variant="outline-secondary" type="reset" onClick={close}>Cancel</Button>
		      <Button variant="outline-success" disabled={loading} type="submit">Create</Button>
		    </div>
		  </Form>
		</Modal>
	)
}