import React from 'react'
import {Redirect} from 'react-router-dom'
import Form, {SimpleSelect, Input, Password} from 'form'
import {Link} from 'common/Links'

import {useMe, useMutation} from 'hooks'
import {mutationSignUp} from 'graph'

export default function SignUp()
{
  const me = useMe()
  const [signUp, {client, error, loading}] = useMutation(mutationSignUp, {
    onError(){},
    onCompleted: data => {
      console.log('Resetting store', data)
      client.resetStore()
    }
  })

  if (me) {
    return (
      <Redirect to="/me" />
    )
  }

  const onSubmit = ({country, ...input}) => signUp({
    variables: {
      input: {
        ...input,
        locale: `en_${country}`
      }
    }
  })

  return (
    <div className="container">
      <div className="row text-center my-4">
        <div className="col">
          <h1 className="display-3">
            Sign up<br/>
            <span className="display-4 text-muted fw-lighter">Become an active part of Thruhiker</span></h1>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
          <Form
            onSubmit={onSubmit}
            error={error}
          >
            <Input
              name="username"
              label="Username"
              placeholder="Enter your username" 
              autoComplete="off"
              rules={{
                required: 'Please choose a username.'
              }}
            />
            <p className="form-text text-muted mb-3">
              Your username is unique on Thruhiker's network.
            </p>
            <Input
              name="email"
              label="E-Mail Address"
              placeholder="Enter your email address" 
              autoComplete="off"
              outerClassName="mt-3"
              type="email"
              rules={{
                required: 'Please enter a valid email address.'
              }}
            />
            <Password
              name="password"
              label="Password"
              placeholder="Enter your password"
              autoComplete="off"
              outerClassName="mt-3"
              rules={{
                required: 'Please choose your password.'
              }}
            />
            <hr/>
            <Input
              name="firstname"
              label="Firstname"
              placeholder="Enter your firstname" 
              autoComplete="off"
              outerClassName="mt-3"
              rules={{
                required: 'Please enter your firstname.'
              }}
            />
            <Input
              name="lastname"
              label="Lastname"
              placeholder="Enter your lastname" 
              autoComplete="off"
              outerClassName="mt-3"
              rules={{
                required: 'Please enter your lastname.'
              }}
            />
            <p className="form-text text-muted mb-3">
              The name by which you are known makes it easier for people to find your account.
              Therefore, use your full name.
            </p>
            <hr/>
            <SimpleSelect
              name="country"
              label="Country"
              placeholder="Where are you from"
              options={SimpleSelect.COUNTRY_OPTIONS}
              rules={{
                required: 'Please select the country you are from.'
              }}
            />
            <div className="d-grid my-3">
              <button type="submit" disabled={loading} className="btn btn-lg btn-primary">
                Sign up
              </button>
            </div>
            <div className="text-center">
              <small className="text-muted text-center">
                Allready have an account? <Link to="sign-in">Sign in</Link>.
              </small>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}