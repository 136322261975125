import {getMiddleOfLine} from 'utils';

export function getMiddleTouchOnElement(touches, boundingRect) {
    const middleTouch = getMiddleOfLine(touches[0], touches[1]);

    return {
        clientX: middleTouch.clientX - boundingRect.left,
        clientY: middleTouch.clientY - boundingRect.top,
    };
}
