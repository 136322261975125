import {ApolloClient, from} from '@apollo/client'
import {onError} from "@apollo/client/link/error";

import {createUploadLink} from 'apollo-upload-client'
import OfflineLink from './OfflineLink'
import {initCache, operationStore} from './cache'
import {isDev} from 'utils'

const resolveUri = () => isDev() ? `${process.env.REACT_APP_HTTP_BASE_URI}/graph` : '/graph'


// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
})

const defaultOptions = {
  watchQuery: {
    //fetchPolicy: 'cache-first',
    //errorPolicy: 'none'
  },
  query: {
    //fetchPolicy: 'cache-first',
    //errorPolicy: 'none'
  },
  mutate: {
    //mutate: {errorPolicy: 'all'}
  }
}

export const offlineLink = new OfflineLink({
  storage: operationStore,
  sequential: true
})

const uploadLinkOptions = {
  uri: resolveUri(),
  credentials: isDev() ? 'include' : 'same-origin',
  cache: isDev() ? 'default' : 'no-store'
};

const uploadLink = createUploadLink(uploadLinkOptions);

const link = from([
  errorLink,
  offlineLink,
  uploadLink
])

async function initClient(setClient)
{
  const cache = await initCache()
  const client = new ApolloClient({
    link,
    cache,
    defaultOptions,
    onError(error) {
      console.log(error)
    }
  })

  await offlineLink.setup(client)

  setClient(client)
}

export {initClient}