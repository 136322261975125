import React from 'react'
import {useState} from 'hooks'
import {uuidv4} from 'utils'

export const ToastContext = React.createContext({})
export const ToastInstanceContext = React.createContext({})

function useToastState() {
	const [stack, setStack] = useState({})

	const open = (component, props={}) => {
		const id = uuidv4()
		setStack({
			...stack, 
			[id]: {
				component,
				props,
				isOpen: true
			}
		})

		return id
	}

	const close = id => {
		setStack({
			...stack,
			[id]: {
				...stack[id],
				isOpen: false
			}
		})
	}

	const unmount = id => {
		const {[id]:_, ...nextStack} = stack
		setStack(nextStack) 
	}

	return {
		stack, 
		open,
		close,
		unmount
	}
}

export function ToastProvider({children}) {

	const state = useToastState()

	return (
		<ToastContext.Provider value={state}>
			{children}
		</ToastContext.Provider>
	)
}

export function ToastInstanceProvider({children, ...value})
{
	return (
		<ToastInstanceContext.Provider value={value}>
			{children}
		</ToastInstanceContext.Provider>
	)
}