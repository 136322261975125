import {useContext} from 'hooks'
import {ModalContext} from 'context'
import {useDeepCompareEffect, useState} from 'hooks'

export default function useModal(component, props = {}) {
	const {open, update} = useContext(ModalContext)
	const [id, setId] = useState()

	useDeepCompareEffect(() => {
		if (id) {
			update(id, props)
		}
	}, [id, props])

	return () => {
		const id = open(component, props)
		setId(id)
	}
}