import {useRef, useState, useCallback, useEffect, useLayoutEffect} from 'react';
import {useSpring} from 'react-spring';
import {useDrag} from 'react-use-gesture';
import {clamp} from 'utils';

function useElementWidth(ref) {
    const [width, setWith] = useState(
        window?.innerWidth || 0
    );

    useLayoutEffect(() => {
        function updateSize() {
          setWith(ref.current?.offsetWidth || 0);
        }

        window.addEventListener('resize', updateSize);
        updateSize();

        return () => window.removeEventListener('resize', updateSize);
    }, [width, ref]);

    return width
}

export function useSlider({containerRef, initialSlide, slides}) {
    const index = useRef(initialSlide);
    // Slide numbers (for display purposes only)
    const [currentSlide, updateSlide] = useState(initialSlide);
    const [zooming, setZooming] = useState(false);

    const containerWidth = useElementWidth(containerRef);
    const [{x, scale}, set] = useSpring(() => ({
        x: containerWidth * index.current,
        scale: 1,
        config: {
            tension: 270,
            clamp: true,
        },
    }));

    useEffect(() => {
      set({
          x: -currentSlide * containerWidth
      })
    }, [set, currentSlide, containerWidth])

    const onScale = useCallback(
        (slideProps) => {
            set({scale: slideProps.scale});
            if (slideProps.scale === 1) {
                setZooming(false);
            } else {
                setZooming(true);
            }
        },
        [set]
    );

    const clampSlides = (idx) => clamp(idx, 0, slides.length - 1);
    const bind = useDrag(
        ({
            down,
            movement: [movementX],
            direction: [directionX],
            distance,
            swipe: [swipeX],
            cancel,
            touches,
        }) => {
            // We don't want to interrupt the pinch-to-zoom gesture
            if (touches > 1) {
                cancel();
            }

            // We have swiped past halfway
            if (!down && distance > containerWidth / 2) {
                index.current = clampSlides(index.current - directionX);
            } else if (swipeX !== 0) {
                // We have detected a swipe - update the new index
                index.current = clampSlides(index.current - swipeX);
            }

            // Animate the transition
            set({
                x: -index.current * containerWidth + (down ? movementX : 0),
                immediate: down,
            });

            // Update the slide number for display purposes
            updateSlide(index.current);
        },
        {
            axis: 'x',
            bounds: {
                left: currentSlide === slides.length - 1 ? 0 : -Infinity,
                right: index.current === 0 ? 0 : Infinity,
                top: 0,
                bottom: 0,
            },
            rubberband: true,
            enabled: slides.length > 1,
        }
    );

    return {zooming, scale, currentSlide, bind, x, onScale, updateSlide};
}
