import React from 'react';
import Button from './Button';
import {Link} from 'common/Links';

import {useMe, useMutation} from 'hooks';
import {
	mutationSubscribeUser,
	mutationUnsubscribeUser,
	fragmentNewSubscription,
} from 'graph';
import {qs} from 'utils';

function InactiveButton({user}) {
	const query = qs.stringify({
		return_to: `/users/${user.username}`,
		reason: 'account_required',
	});

	return (
		<Button
			as={Link}
			to={`/sign-in?${query}`}
			title="You must be logged in to subsribe users"
		>
			Subscribe
		</Button>
	);
}

export default function SubscribeUserButton({user, ...props}) {
	const me = useMe();

	const mutationOptions = {
		variables: {
			input: {
				id: user.id,
			},
		},
	};
	const [subscribe, {loading: loadingSubscribe}] = useMutation(
		mutationSubscribeUser,
		{
			...mutationOptions,
			update(cache, {data}) {
				const {subscription} = data.subscribeUser;

				const receiverRef = cache.identify(subscription.receiver);
				const senderRef = cache.identify(subscription.sender);

				const newSubscriptionRef = cache.writeFragment({
					data: subscription,
					fragment: fragmentNewSubscription,
				});

				cache.modify({
					id: receiverRef,
					fields: {
						subscriptionsReceived({edges, pagination}) {
							return {
								pagination: {
									...pagination,
									total: pagination.total + 1,
								},
								edges: [...edges, newSubscriptionRef],
							};
						},
					},
				});

				cache.modify({
					id: senderRef,
					fields: {
						subscriptionsSend({edges, pagination}) {
							return {
								pagination: {
									...pagination,
									total: pagination.total + 1,
								},
								edges: [...edges, newSubscriptionRef],
							};
						},
					},
				});
			},
		}
	);
	const [unsubscribe, {loading: loadingUnsubscribe}] = useMutation(
		mutationUnsubscribeUser,
		{
			...mutationOptions,
			update(cache, {data}) {
				const {subscription} = data.unsubscribeUser;

				const deletedSubscriptionId = subscription.id;

				const deletedSubscriptionRef = cache.identify(subscription);
				const senderRef = cache.identify(subscription.sender);
				const receiverRef = cache.identify(subscription.receiver);

				cache.modify({
					id: receiverRef,
					fields: {
						subscriptionsReceived(
							{edges, pagination},
							{readField}
						) {
							return {
								pagination: {
									...pagination,
									total: pagination.total - 1,
								},
								edges: edges.filter(
									(subscriptionRef) =>
										deletedSubscriptionId !==
										readField('id', subscriptionRef)
								),
							};
						},
					},
				});

				cache.modify({
					id: senderRef,
					fields: {
						subscriptionsSend({edges, pagination}, {readField}) {
							return {
								pagination: {
									...pagination,
									total: pagination.total - 1,
								},
								edges: edges.filter(
									(subscriptionRef) =>
										deletedSubscriptionId !==
										readField('id', subscriptionRef)
								),
							};
						},
					},
				});

				cache.evict({id: deletedSubscriptionRef});
				cache.gc();
			},
		}
	);

	const loading = loadingSubscribe || loadingUnsubscribe;

	// Annonymous users cannot create subscriptions
	if (!me) {
		return <InactiveButton user={user} />;
	}

	// Users cannot subscribe themselves
	if (me.id === user.id) {
		return null;
	}

	const {viewerSubscriptionState} = user;

	switch (viewerSubscriptionState) {
		case 'PRENDING':
		case 'APPROVED':
			return (
				<Button
					variant="white"
					onClick={() => unsubscribe()}
					disabled={loading}
				>
					{viewerSubscriptionState === 'PENDING'
						? 'Pending'
						: 'Subscribed'}
				</Button>
			);
		case 'NONE':
		default:
			return (
				<Button onClick={() => subscribe()} disabled={loading}>
					Subscribe
				</Button>
			);
	}
}
