import React from 'react';
import {ApolloProvider} from '@apollo/client'
import {SessionProvider} from 'context/SessionContext'
import {
	OnlineStatusProvider, 
	ModalContext, 
	ModalProvider, 
	ModalInstanceProvider,
	ToastContext, 
	ToastProvider
} from 'context'

import Navigation from 'common/Navigation'
import OfflineAlert from 'common/OfflineAlert'
import BetaAlert from 'common/BetaAlert'
import Updater from 'common/Updater'

import {useState, useEffect, useContext} from 'hooks'

import {Router as RouterProvider, Switch,	Route} from 'react-router-dom'

import history from './services/history'
import {initClient} from './services/client'

// Pages
import Home from 'pages/Home'
import Adventure from 'pages/Adventure'
import User, {Me} from 'pages/User'
import SignIn from 'pages/SignIn'
import SignUp from 'pages/SignUp'
import ResetPassword from 'pages/ResetPassword'

function Explore() {
	return (
		<div className="row text-center my-4">
			<div className="col">
				<h1 className="display-3">
					Explore<br/>
					<span className="display-4 text-muted fw-lighter">The next adventure is waiting</span></h1>
				<p>I'm working on that feature</p>
			</div>
		</div>
	)
}


function ModalRoot() {
		const {close, unmount, stack} = useContext(ModalContext)
		return Object.entries(stack).map(([id, state]) => {
			const {component: Component, props, isOpen} = state

			const innerClose = () => close(id)
			const innerUnmount = () => unmount(id)
			return (
				<ModalInstanceProvider key={id} close={innerClose} unmount={innerUnmount} isOpen={isOpen}>
					<Component {...props} id={id} close={innerClose} />
				</ModalInstanceProvider>
			)

		})
}

function ToastRoot() {
		const {close, unmount, stack} = useContext(ToastContext)
		const toasts = Object.entries(stack).map(([id, state]) => {
			const {component: Component, props, isOpen} = state
			return (
				<Component 
					{...props}
					key={id}
					id={id}
					close={() => close(id)}
					unmount={() => unmount(id)}
					isOpen={isOpen}/>
			)
		})

		return (
			<div className="position-fixed bottom-0 end-0 p-3" style={{zIndex: 11}}>
				{toasts}
			</div>
		)
}

function Container()
{
	return (
		<>
			<Navigation/>
			<Updater/>
			<OfflineAlert/>
			<BetaAlert/>
			<div className="main-content">
				<div className="container">
					<ModalRoot/>
					<ToastRoot/>
					<Switch>
						<Route exact path="/" component={Home}/>
						<Route path="/explore" component={Explore}/>
						<Route path="/me/:tab?" component={Me}/>
						<Route path="/users/:username/:tab?" component={User}/>
						<Route path="/adventures/:id" component={Adventure}/>
						<Route path="/sign-in" component={SignIn}/>
						<Route path="/sign-up" component={SignUp}/>
						<Route path="/reset-password/:token?" component={ResetPassword}/>
					</Switch>
				</div>
			</div>
		</>
	)
}

function App()
{
  const [client, setClient] = useState()

  useEffect(() => {
    initClient(setClient).catch(console.error)
  }, [])

  if (!client) {
  	return (
  		null
  	)
  }

	return (
		<OnlineStatusProvider>
			<ApolloProvider client={client}>
				<RouterProvider history={history}>
					<SessionProvider>
						<ModalProvider>
							<ToastProvider>
								<Container/>
							</ToastProvider>
						</ModalProvider> 
					</SessionProvider>
				</RouterProvider>
			</ApolloProvider>
		</OnlineStatusProvider>
	);
}

export default App