import React from 'react'
import {useForm, FormProvider} from 'react-hook-form'
import {useDeepCompareEffect, useEffect} from 'hooks'
import {noop} from 'utils'

export default function Form({
	defaultValues, 
	defaultPlaceholders,
	resolver,
	children,
	error,
	onSubmit = noop, 
	readOnly = false,
	isLoading = false,
}) {

	const methods = useForm({
		defaultValues
	})

	const {setError, handleSubmit, reset} = methods

	useDeepCompareEffect(() => {
		if (defaultValues) {
			reset(defaultValues)
		}
	}, [defaultValues])

	useEffect(() => {
		if (!error?.graphQLErrors) {
			return
		}

		error.graphQLErrors.filter(
			({category}) => category === 'validation'
		).forEach(
			({invalid={}}) => {
				Object.entries(invalid).forEach(entry => {
					const [key, [{name, message}]] = entry
					setError(key, {type: name, message})
				})
			}
		)
	}, [error, setError])

	const onInnerSubmit = (data, e) => onSubmit(data, methods, e)

	return (
		<form onSubmit={handleSubmit(onInnerSubmit)} autoComplete="off">
			<input autoComplete="false" name="hidden" type="text" style={{display:'none'}}/>
			<FormProvider 
				{...methods} 
				readOnly={readOnly} 
				defaultPlaceholders={defaultPlaceholders} 
				defaultValues={defaultValues}
				isLoading={isLoading}
			>
				{children}
			</FormProvider>
		</form>
	)
}

export function UncontrolledForm({
	form,
	defaultPlaceholders,
	validationErrors, 
	children, 
	onSubmit = noop, 
	readOnly = false,
	isLoading = false
}) {

	const methods = form
	const {setError, handleSubmit} = methods

	useEffect(() => {
		if (validationErrors) {
			validationErrors.fields.forEach(
				field => field.errors.forEach(
					({name: type, message}) => {
						console.log(`Setting error ${message} with name ${type} for field ${field.key}`)
						setError(field.key, {type, message})
					}
				)
			)
		}	
	}, [validationErrors, setError])

	const onInnerSubmit = (data, e) => onSubmit(data, methods, e)

	return (
		<form onSubmit={handleSubmit(onInnerSubmit)} autoComplete="off">
			<input autoComplete="false" name="hidden" type="text" style={{display:'none'}}/>
			<FormProvider 
				{...methods} 
				readOnly={readOnly} 
				defaultPlaceholders={defaultPlaceholders} 
				isLoading={isLoading}
			>
				{children}
			</FormProvider>
		</form>
	)
}