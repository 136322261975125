import React from 'react'
import {isDev} from 'utils'

export default function Error({error, ignoreOffline}) {
  if (!error) {
    return null
  }

  const {networkError, graphQLErrors} = error

  if (networkError) {
    if (ignoreOffline) {
      return null
    }

    return (
      <div className="alert alert-warning" role="alert">
        <h4 className="alert-heading">Oh snap! You got an network error!</h4>
        <p className="mb-0">Something went wrong while fetching data from the server. Are you offline?</p>
      </div>
    )
  }

  const errors = graphQLErrors?.filter(
    ({category}) => category !== 'validation'
  )

  if (!errors || errors.length === 0) {
    return null
  }

	return (
    <div className="alert alert-warning" role="alert">
      <h4 className="alert-heading">Oh snap! You got an error!</h4>
      <p className="mb-0">Beats the hell out of me, but something went wrong.</p>
      {isDev() && (
        <div>
          <pre>
            {JSON.stringify(errors, null, 4)}
          </pre>
        </div>
      )}
    </div>
	)
}