import React from 'react';
import {useContext} from 'hooks';

const EntityContext = React.createContext({});

export const EntityProvider = ({children, ...entities}) => {
	const parentContext = useContext(EntityContext);
	const value = {...parentContext, ...entities};

	return (
		<EntityContext.Provider value={value}>
			{children}
		</EntityContext.Provider>
	);
};
export const EntityConsumer = EntityContext.Consumer;
export default EntityContext;