export const quotes = {
	createAdventure: [
		{
			text: 'Avoiding danger is no safer in the long run than outright exposure. Life is either a daring adventure or nothing.',
			source: 'Helen Keller'
		},
		{
			text: 'Adventure is worthwhile in itself.',
			source: 'Amelia Earhart'
		},
		{
			text: 'If we were meant to stay in one place, we’d have roots instead of feet.',
			source: 'Rachel Wolchin'
		},
		{
			text: 'The danger of adventure is worth a thousand days of ease and comfort.',
			source: 'Paulo Coelho'
		},
	],
	updateUser: [
		{
			text: 'Things change. And friends leave. Life doesn\'t stop for anybody.',
			source: ''
		},
		{
			text: 'Everyone thinks of changing the world, but no one thinks of changing himself.',
			source: ''
		},
		{
			text: 'Yesterday I was clever, so I wanted to change the world. Today I am wise, so I am changing myself.',
			source: ''
		},
		{
			text: 'They always say time changes things, but you actually have to change them yourself.',
			source: ''
		},
	],
	searchLocation: [
		{
			text: 'Not all those who wander are lost.',
			source: 'J.R.R. Tolkien, The Fellowship of the Ring',
		}
	]
}