import React from 'react'

const FeedContext = React.createContext({})

export const FeedProvider = ({withAdventureRef, children}) => {
	const value = {
		withAdventureRef
	}

	return (
		<FeedContext.Provider value={value}>
			{children}
		</FeedContext.Provider>
	)
}
export const FeedConsumer = FeedContext.Consumer
export default FeedContext