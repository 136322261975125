import React from 'react'
import {Modal} from 'common/modals'
import {Button} from 'common/buttons'
import Form, {Textarea} from 'form'

/*
import {useMutation} from 'hooks'
import {mutationCreateAdventure} from 'graph'
*/

export function ReportPostModal(props)
{
	const {post, close} = props
	/*
	const [doCreateAdventure, {loading}] = useMutation(mutationCreateAdventure)
	const onSubmit = input => doCreateAdventure({
		variables: {
			input
		}
	})
	*/

	const onSubmit = () => {}

	return (
		<Modal {...props}>
			<Form 
				onSubmit={onSubmit}
				defaultValues={post}
			>
				<div className="modal-body">
					<div>
		      	<h2>
		      		<small className="text-muted">Report Post</small><br/>
		      		What bothers you about this post?
		      	</h2>
		      </div>
		      <Textarea
		      	name="message"
		      	placeholder="Describe the problem"
		      	rules={{
		      		required: 'Please describe what the problem with this post is'
		      	}}
		      />
		    </div>
		    <div className="modal-footer">
		      <Button variant="outline-secondary" type="reset" onClick={close}>Cancel</Button>
		      <Button variant="outline-secondary" type="submit">Report</Button>
		    </div>
		  </Form>
		</Modal>
	)
}