export function isTouchesInsideRect(touches, rect) {
    return Array.prototype.every.call(
        touches,
        (touch) =>
            touch.clientX <= rect.right &&
            touch.clientX >= rect.left &&
            touch.clientY <= rect.bottom &&
            touch.clientY >= rect.top
    );
}
