import {ApolloLink, Observable} from 'apollo-link'
import gql from 'graphql-tag'
import {uuidv4} from 'utils'
import {debounce, isObject, isArray, mapValues} from 'lodash'

function mapValuesDeep(data, callback) {
  const mapped = callback(data)

  if (mapped !== data) {
    return mapped
  }

  if (isArray(data)) {
    return data.map(item => mapValuesDeep(item, callback))
  }

  return isObject(data)
    ? mapValues(data, item => mapValuesDeep(item, callback))
    : data
}

/**
 * Returns file content as ArrayBuffer
 */

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}

async function serializeVariables(variables)
{
  const files = new Map()
  const replaced = mapValuesDeep(variables, item => {
    if (item instanceof File) {
      const id = uuidv4()
      const {name, type, lastModified} = item

      files.set(id, item)

      return {
        __type: 'Cached_File',
        id,
        name,
        type,
        lastModified
      }
    }

    return item
  })

  await Promise.all(
    Array.from(
      files
    ).map(
      ([id, file]) => readFileAsync(file).then(content => files.set(id, content))
    )
  )

  return mapValuesDeep(replaced, item => item?.__type === 'Cached_File' ? {...item, content: files.get(item.id)} : item)
}

function deserializeVariables(variables)
{
  return mapValuesDeep(variables, item => {
    if (item?.__type === 'Cached_File') {
      const {content, name, type, lastModified} = item
      return new File([content], name, {
        type,
        lastModified
      })
    }

    return item
  })
}

const syncStatusQuery = gql`
  query syncStatus {
    mutations
    inflight
  }
`;

export default class OfflineLink extends ApolloLink {
  /**
   * storage
   * Provider that will persist the mutation queue. This can be any AsyncStorage compatible storage instance.
   * 
   * retryInterval
   * Milliseconds between attempts to retry failed mutations. Defaults to 30,000 milliseconds.
   * 
   * sequential
   * Indicates if the attempts should be retried in order. Defaults to false which retries all failed mutations in parallel.
   * 
   * retryOnServerError
   * Indicates if mutations should be reattempted if there are server side errors, useful to retry mutations on session expiration. Defaults to false.
   */
  constructor({storage, retryInterval = 30000, sequential = false, retryOnServerError = false}) {
    super()

    if (!storage) {
      throw new Error("Storage is required, it can be an AsyncStorage compatible storage instance.")
    }

    this.storage = storage
    this.sequential = sequential
    this.retryOnServerError = retryOnServerError
    this.queue = new Map()
    this.delayedSync = debounce(this.sync, retryInterval)
    this.prefix = 'ThruhikerOfflineLink'

    if ('onLine' in navigator) {
      this.setOnlineStatus(navigator.onLine)
      window.addEventListener('offline', () => this.setOnlineStatus(false))
      window.addEventListener('online', () => this.setOnlineStatus(true))
    } 
    else {
      this.setOnlineStatus(true)
    }
  }

  setOnlineStatus(status)
  {
    if (status) {
      //this.delayedSync.invoke()
    }
    else {
      this.delayedSync.cancel()
    }
  }

  request(operation, forward) {
    const context = operation.getContext()
    const {query, variables} = operation || {}

    if (!context.optimisticResponse) {
      // If the mutation does not have an optimistic response then we don't defer it
      return forward(operation)
    }

    return new Observable(observer => {

      const addingPromise = this.add({
        mutation: query,
        variables
      })

      const subscription = forward(operation).subscribe({
        next: result => {
          // Mutation was successful so we remove it from the queue since we don't need to retry it later
          addingPromise.then(attemptId => this.remove(attemptId))
          observer.next(result)
        },

        error: async networkError => {
          // Mutation failed so we try again after a certain amount of time.
          this.delayedSync()
          console.log('Got network error will retry in 30 seconds')

          // Resolve the mutation with the optimistic response so the UI can be updated
          observer.next({
            data: context.optimisticResponse,
            dataPresent: true,
            errors: []
          })

          // Say we're all done so the UI is re-rendered.
          observer.complete()
        },

        complete: () => observer.complete()
      })

      return () => {
        subscription.unsubscribe()
      };
    });
  }

  /**
   * Obtains the queue of mutations that must be sent to the server.
   * These are kept in a Map to preserve the order of the mutations in the queue.
   */
  async getQueue() {
    let storedAttemptIds = []
    let map 

    return new Promise((resolve, reject) => {
      // Get all attempt Ids
      this.storage.getItem('operation_ids').then(storedIds => {
        map = new Map();

        if (storedIds) {
          storedAttemptIds = storedIds.split(',');
          storedAttemptIds.forEach((storedId, index) => {

            // Get file of name '<prefix><UUID>'
            this.storage.getItem('operation_' + storedId).then(({variables, ...operation}) => {
              operation.variables = deserializeVariables(variables)
              map.set(storedId, operation);

              // We return the map
              if (index === storedAttemptIds.length - 1) {
                resolve(map)
              }
            })
          })
        } else {
          resolve(map)
        }
      })
      .catch(err => {
        // Most likely happens the first time a mutation attempt is being persisted.
        resolve(new Map());
      });
    });
  }

  /**
   * Updates a SyncStatus object in the Apollo Cache so that the queue status can be obtained and dynamically updated.
   */
  updateStatus(inflight) {
    this.client.writeQuery({
      query: syncStatusQuery, 
      data: {
        __typename: "SyncStatus",
        mutations: this.queue.size,
        inflight
      }
    });
  }

  /**
   * Persist the queue so mutations can be retried at a later point in time.
   */
  async saveQueue(attemptId, operation) {
    // Saving Ids file
    this.storage.setItem('operation_ids', [...this.queue.keys()].join())
    this.updateStatus(false)
  }

  /**
   * Add a mutation attempt to the queue so that it can be retried at a later point in time.
   */
  async add(attempt) {
    // We give the mutation attempt a random id so that it is easy to remove when needed (in sync loop)
    const attemptId = uuidv4()
    attempt.variables = await serializeVariables(attempt.variables)

    console.log(`Adding attempt ${attemptId}`, attempt)    

    this.queue.set(attemptId, attempt)

    await Promise.all([
      // Save the queue
      this.saveQueue(),
      // Save the attempt
      this.storage.setItem(attemptId, attempt)
    ])

    return attemptId
  }

  /**
   * Remove a mutation attempt from the queue.
   */
  async remove(attemptId) {
    console.log(`Removing attempt ${attemptId}`)
    this.queue.delete(attemptId)

    await Promise.all([
      this.saveQueue(),
      this.storage.removeItem(attemptId)
    ])

    return true
  }

  /**
   * Takes the mutations in the queue and try to send them to the server again.
   */
  async sync() {
    const queue = this.queue;

    // There's nothing in the queue to sync, no reason to continue.
    if (queue.size < 1) {
      return
    }

    // Update the status to be "in progress"
    this.updateStatus(true);

    const attempts = Array.from(queue);

    for (const [attemptId, attempt] of attempts) {
      const success = await this.client
        .mutate(attempt)
        // Mutation was successfully executed so we remove it from the queue
        .then(() => this.remove(attemptId))
        // Mutation failed
        .catch(err => {
          // There are GraphQL errors, which means the server processed the request so we can remove the mutation from the queue
          if (!err.networkError) {
            return this.remove(attemptId).then(() => true)
          }
          
          // There was a network error so we have to retry the mutation
          return false
        })

      // The last mutation failed so we don't attempt any more
      if (!success) {
        break
      }
    }

    // Remaining mutations in the queue are persisted
    await this.saveQueue();

    if (queue.size > 0) {
      // If there are any mutations left in the queue, we retry them at a later point in time
      this.delayedSync();
    }
  }

  /**
   * Configure the link to use Apollo Client and immediately try to sync the queue (if there's anything there).
   */
  async setup(client) {
    console.log('Setup offline link')
    this.client = client
    this.queue = await this.getQueue()

    console.log('Current offline queue', this.queue)

    return this.sync()
  }
}

export {syncStatusQuery}